import { ReactElement } from 'react';

import DetailsWrapper from './DetailsWrapper';
import { CopyToClipboardByIcon } from 'src/components/common/CopyToClipboard/CopyToClipboard';
import { DetailsProps } from '../constants/infoPanelConstants';
import { DetailsRow, DetailsUrlWrapper } from '../InfoPanelLayout';
import { getAccountUrlPrefix } from 'src/helpers/commonHelpers';
import { InternalPageLink } from 'src/components/layouts/Layout';
import { stringifyDate } from 'src/helpers/dateTimeHelpers';

const PayoutDetails = ({ accountId, txnData, itemId: payoutId }: DetailsProps): ReactElement => {
  const ledgers = txnData?.ledgers || [];
  const ledger = ledgers.find(({ payout }) => payout?.id === payoutId);
  const payout = ledger?.payout;

  if (!payout) {
    return (
      <DetailsWrapper>
        <DetailsRow label="No data found for the payout" value={payoutId} />
      </DetailsWrapper>
    );
  }

  const payoutUrl =
    accountId && payoutId ? (
      <InternalPageLink
        to={`${getAccountUrlPrefix(accountId)}/payout/${encodeURIComponent(payoutId)}`}
        rel="noopener"
        target="_blank"
      >
        See full payout details
      </InternalPageLink>
    ) : (
      ''
    );

  return (
    <DetailsWrapper label="Payout Details">
      <DetailsRow label="ID:" value={<CopyToClipboardByIcon text={payoutId} />} />
      <DetailsRow label="Payout Amount:" value={payout?.payoutAmount} />
      <DetailsRow label="Currency:" value={payout?.currency} />
      <DetailsRow label="Created at:" value={stringifyDate(payout?.createdAt)} />
      <DetailsRow label="Funding Agent ID:" value={<CopyToClipboardByIcon text={payout?.fundingAgentId} />} />
      <DetailsRow label="Ledger Count:" value={payout?.ledgerCount} />
      <DetailsRow label="Net Ledger Amount:" value={payout?.netLedgerAmount} />
      <DetailsRow label="Payout Fee:" value={payout?.payoutFee} />
      <DetailsRow label="Payout Time:" value={stringifyDate(payout?.payoutTime)} />
      <DetailsRow label="Sub Total Amount:" value={payout?.subTotalAmount} />
      <DetailsRow label="Transfer ID:" value={<CopyToClipboardByIcon text={payout?.transferId} />} />
      <DetailsRow label="Transfer Request ID:" value={<CopyToClipboardByIcon text={payout?.transferRequestId} />} />
      <DetailsRow label="Transfer Status:" value={payout?.transferStatus} />
      <DetailsRow label="UpdatedAt:" value={stringifyDate(payout?.updatedAt)} />
      <DetailsUrlWrapper>{payoutUrl}</DetailsUrlWrapper>
    </DetailsWrapper>
  );
};

export default PayoutDetails;
