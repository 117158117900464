import { get, map } from 'lodash';
import { ReactElement } from 'react';

import Table from 'src/components/table/Table';
import { capitalizeFirstLetter } from 'src/helpers/commonHelpers';
import { convertHoursFrom24to12 } from 'src/helpers/dateTimeHelpers';
import { DAYS_OF_WEEK, DISABLED_SCHEDULE_LABEL } from 'src/constants/dateTimeConstants';

interface ICobSchedule {
  monday: number;
  mondayFundingAgent: string;
  mondayFundingType: string;
  mondayFundingValue: number;
  tuesday: number;
  tuesdayFundingAgent: string;
  tuesdayFundingType: string;
  tuesdayFundingValue: number;
  wednesday: number;
  wednesdayFundingAgent: string;
  wednesdayFundingType: string;
  wednesdayFundingValue: number;
  thursday: number;
  thursdayFundingAgent: string;
  thursdayFundingType: string;
  thursdayFundingValue: number;
  friday: number;
  fridayFundingAgent: string;
  fridayFundingType: string;
  fridayFundingValue: number;
  saturday: number;
  saturdayFundingAgent: string;
  saturdayFundingType: string;
  saturdayFundingValue: number;
  sunday: number;
  sundayFundingAgent: string;
  sundayFundingType: string;
  sundayFundingValue: number;
  currency: string;
  timezone: string;
}

interface SingleCobScheduleProps {
  schedule: ICobSchedule;
}

interface CobSchedulesProps {
  schedules: ICobSchedule[];
}

interface NormalizedSchedule {
  currency: string;
  dayOfWeek: string;
  fundingAgent: string;
  fundingType: string;
  fundingValue: number;
  time: string;
  timezone: string;
}

export default function CobSchedules({ schedules = [] }: CobSchedulesProps) {
  const renderedSchedules = map(
    schedules,
    (schedule: ICobSchedule, idx): ReactElement => <CobSchedule key={`cobSchedule${idx}`} schedule={schedule} />
  );

  return <>{renderedSchedules}</>;
}

function CobSchedule({ schedule }: SingleCobScheduleProps): ReactElement {
  const currency = get(schedule, 'currency', '');
  const timezone = get(schedule, 'timezone', '');

  const getScheduleList = (schedules: Record<string, any>): NormalizedSchedule[] => {
    return map(DAYS_OF_WEEK, dayOfWeek => ({
      currency,
      dayOfWeek,
      timezone,
      time: convertHoursFrom24to12(get(schedules, dayOfWeek, '')),
      fundingAgent: get(schedules, `${dayOfWeek}FundingAgent`, ''),
      fundingType: get(schedules, `${dayOfWeek}FundingType`, ''),
      fundingValue: get(schedules, `${dayOfWeek}FundingValue`, ''),
    }));
  };

  const weekSchedule = getScheduleList(schedule);

  return (
    <Table
      columnsConfig={[
        {
          attr: 'currency',
          label: 'Currency',
        },
        {
          attr: 'dayOfWeek',
          label: 'Day of the week',
          customRenderer: (item: NormalizedSchedule) => capitalizeFirstLetter(item.dayOfWeek || ''),
        },
        {
          attr: 'time',
          label: 'Time',
          customRenderer: (item: NormalizedSchedule) => {
            return item.time && item.time !== DISABLED_SCHEDULE_LABEL ? `${item.time} ${item.timezone || ''}` : '';
          },
        },
        {
          attr: 'fundingType',
          label: 'Funding Type',
        },
        {
          attr: 'fundingValue',
          label: 'Funding Value',
        },
        {
          attr: 'fundingAgent',
          label: 'Funding Id',
        },
      ]}
      rows={weekSchedule}
    />
  );
}
