import styled from '@emotion/styled';
import { get, isEmpty } from 'lodash';
import { ReactElement } from 'react';

import AccountInfo from './AccountInfo';
import TxnGraph from './txnGraph/TxnGraph';
import { FullTxnData } from '../constants/txnConstants';

const GraphPanel = ({ data }: { data: FullTxnData | null; baseTxnId: string }): ReactElement | null => {
  if (isEmpty(data)) {
    return null;
  }

  const txnData = get(data, 'transactionData.baseTxn');

  return (
    <GraphWrapper>
      <AccountInfo data={data?.accountData} />
      {txnData ? <TxnGraph txnData={txnData} /> : null}
    </GraphWrapper>
  );
};

const GraphWrapper = styled('div')`
  flex-grow: 1;
`;

export default GraphPanel;
