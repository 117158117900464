import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

export default function CircularLoader() {
  return (
    <Backdrop
      sx={{
        backgroundColor: 'rgba(0, 0, 0, 0.08)',
        color: '#aaa',
        transitionDuration: 1000,
        zIndex: theme => theme.zIndex.drawer + 1,
      }}
      open
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  );
}
