import apolloClient from 'src/services/apollo/apolloClient';
import { useState } from 'react';

import { SINGLE_ACCOUNT_PAYOUT_QUERY } from 'src/services/apollo/queries/accountQueries';
import { useGlobalProgressBar } from 'src/context/GlobalProgressBarContext';

const useAccountSearch = (accountId: string, requestIdType: string) => {
  const [searchResult, setSearchResult] = useState<any[] | null>(null);
  const { setIsLoading } = useGlobalProgressBar();

  const searchFunction = async (payoutIdToFind: string) => {
    if (!payoutIdToFind) {
      setSearchResult(null);
      return;
    }

    try {
      setIsLoading(true);

      const { data } = await apolloClient.query({
        query: SINGLE_ACCOUNT_PAYOUT_QUERY,
        variables: { accountId, requestIdType, payoutId: payoutIdToFind },
      });

      const searchResult = data?.singleAccountPayout;

      setSearchResult(searchResult ? [searchResult] : []);
    } catch (err) {
      setSearchResult([]);
    } finally {
      setIsLoading(false);
    }
  };

  return { searchFunction, searchResult };
};

export default useAccountSearch;
