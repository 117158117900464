import { ReactElement, ReactNode, useState } from 'react';
import styled from 'styled-components';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

interface VisibilitySwitcherProps {
  children: ReactNode;
  isVisible?: boolean;
}

const VisibilitySwitcher = (props: VisibilitySwitcherProps): ReactElement => {
  const { children, isVisible = false } = props;
  const [componentIsVisible, setComponentIsVisible] = useState(isVisible);

  const TogglerIcon = componentIsVisible ? VisibilityIcon : VisibilityOffIcon;

  const toggler = (
    <ToggleWrapper>
      <TogglerIcon />
    </ToggleWrapper>
  );

  return children ? (
    <Container onClick={() => setComponentIsVisible(!componentIsVisible)}>
      <ComponentWrapper show={componentIsVisible}>{children}</ComponentWrapper>
      {toggler}
    </Container>
  ) : (
    <>{children}</>
  );
};

const Container = styled('div')`
  align-items: center;
  cursor: pointer;
  display: flex;
`;

interface ComponentWrapperProps {
  show: boolean;
}

const ComponentWrapper = styled.span<ComponentWrapperProps>`
  ${props => (props.show ? '' : 'color: transparent; text-shadow: 0 0 8px #000;')}
`;

const ToggleWrapper = styled('span')`
  align-items: center;
  color: #aaa;
  display: flex;
  padding: 0 8px;
`;

export default VisibilitySwitcher;
