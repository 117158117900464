import { ReactElement, useEffect } from 'react';
import { get } from 'lodash';
import { useQuery } from '@apollo/client';

import PriceProfileInfo from './PriceProfileInfo';
import { Account } from 'src/constants/accountConstants';
import { CURRENT_PRICE_PROFILE } from 'src/services/apollo/queries/pricingQueries';
import { PricingProfile } from '../constants/pricingProfileConstants';

export default function CurrentPriceProfile({
  accountData,
  onPriceProfileInfoLoaded,
}: {
  accountData: Account;
  onPriceProfileInfoLoaded?: (priceProfileId: string) => void;
}): ReactElement {
  const { data: currentPriceProfileResponse, loading: currentPriceProfileIsLoading } = useQuery(CURRENT_PRICE_PROFILE, {
    variables: { businessId: accountData.ownerId },
  });

  const currentPriceProfile = get(currentPriceProfileResponse, 'currentPriceProfile', {}) as PricingProfile;

  useEffect(() => {
    const currentPriceProfileId = get(currentPriceProfileResponse, 'currentPriceProfile.priceProfileId', '');

    onPriceProfileInfoLoaded?.(currentPriceProfileId);
  }, [currentPriceProfileResponse, onPriceProfileInfoLoaded]);

  return (
    <PriceProfileInfo
      priceProfileData={currentPriceProfile}
      priceProfileIsLoading={currentPriceProfileIsLoading}
      isCurrent
    />
  );
}
