import { FocusEventHandler, ReactElement } from 'react';
import styled from '@emotion/styled';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';

import { DropdownItemType } from 'src/components/form/formTypes';

interface DropdownProps {
  disabled?: boolean;
  items: DropdownItemType[];
  fullWidth?: boolean;
  label?: string;
  onBlur?: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  onChange: (event: SelectChangeEvent<unknown>) => void;
  size?: 'medium' | 'small';
  value: string | number;
}

const Dropdown = ({
  disabled = false,
  items = [],
  fullWidth = false,
  label = '',
  onBlur,
  onChange,
  size = 'medium',
  value,
}: DropdownProps): ReactElement => {
  const controlStyles = size === 'small' ? { m: 1 } : {};

  return (
    <FormControl fullWidth={fullWidth} size={size} sx={controlStyles}>
      <InputLabel size="small">{label}</InputLabel>
      <StyledSelect disabled={disabled} label={label} onBlur={onBlur} onChange={onChange} value={value}>
        {items.map(({ label, value }) => (
          <MenuItem dense key={value} value={value}>
            {label}
          </MenuItem>
        ))}
      </StyledSelect>
    </FormControl>
  );
};

const StyledSelect = styled(Select)`
  .MuiSelect-select {
    font-size: 0.8rem;
  }
`;

export default Dropdown;
