/* eslint-disable no-console */
import apmService from './apmService';

export type LogLevelType = 'debug' | 'info' | 'warn' | 'error' | 'none';

const logLevels = ['debug', 'info', 'warn', 'error', 'none'];
const DEFAULT_LOG_LEVEL = 'error';

export class Logger {
  logLevel: LogLevelType;

  // eslint-disable-next-line no-process-env
  constructor(logLevel = process.env.REACT_APP_LOG_LEVEL || '') {
    this.logLevel = logLevels.includes(logLevel) ? (logLevel as LogLevelType) : DEFAULT_LOG_LEVEL;
  }

  shouldLog = (logLevelToCheck: LogLevelType) => logLevels.indexOf(logLevelToCheck) >= logLevels.indexOf(this.logLevel);

  error(message: string) {
    this.shouldLog('error') && console.error(message);
    apmService.captureError(message);
  }

  warn(message: string) {
    this.shouldLog('warn') && console.warn(message);
  }

  info(message: string) {
    this.shouldLog('info') && console.info(message);
  }

  debug(message: string) {
    this.shouldLog('debug') && console.log(message);
  }
}

export default new Logger();
/* eslint-enable no-console */
