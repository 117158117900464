import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import { styled } from '@mui/material/styles';

export default function Spinner() {
  return (
    <Box sx={{ display: 'flex' }}>
      <CircularProgress />
    </Box>
  );
}

export const SpinnerContainer = styled('span')`
  align-items: center;
  display: flex;
  height: 200px;
  flex: 1 1 300px;
  justify-content: center;
  width: 100%;
`;
SpinnerContainer.displayName = 'SpinnerContainer';
