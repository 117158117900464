import { get, isEmpty } from 'lodash';

import { AccountDataProps } from 'src/constants/accountConstants';

const getPayoutStatusMessage = (accountData: AccountDataProps): string => {
  const enabledFeatures = accountData?.enabledFeatures || [];
  const fundingAgents = accountData?.fundingAgents || [];
  const cobSchedules = accountData?.cobSchedules || [];
  const balances = accountData?.balances || [];
  const isLogicalAccount = get(accountData, 'type') === 'LOGICAL';

  const depositFlagIsOn = enabledFeatures.includes('PAYOUT');
  const defaultBankAccount = fundingAgents.find((agent: any) => agent?.default);
  const defaultInactiveBankAccount = defaultBankAccount?.status !== 'ACTIVE';
  const balanceIsZero =
    isLogicalAccount && balances.some((balance: any) => balance?.type === 'AVAILABLE' && balance?.amount === 0);
  const noCob = isEmpty(cobSchedules);

  if (!depositFlagIsOn) {
    return 'The payout will not go out, because the deposit flag is off.';
  }

  if (!defaultBankAccount) {
    return 'The payout will not go out, because there is no default bank account on the file.';
  }

  if (defaultInactiveBankAccount) {
    return 'The payout will not go out, because the default bank account on the file is not active.';
  }

  if (balanceIsZero) {
    return 'There is no activity to pay out as the merchant has no available balance.';
  }

  if (noCob) {
    return 'The payout will not go out, because there is no Close of Business schedule for this merchant.';
  }

  return 'The next payout is expected at the close of business time.';
};

interface PayoutStatusProps {
  data: AccountDataProps;
}

export default function PayoutStatus({ data }: PayoutStatusProps) {
  const status = getPayoutStatusMessage(data);

  return <>{status}</>;
}
