import { ADDITIONAL_FILTERS_TYPE, MAIN_FILTERS_TYPE } from './filterConstants';

export type AccountOrganization = {
  accountId: string;
  ownerType: string;
  ownerId: string;
  createdAt: string;
  updatedAt: string;
};

export enum IncentiveStatus {
  ACTIVE = 'ACTIVE',
  EXPIRED = 'EXPIRED',
}

export type Incentive = {
  status: IncentiveStatus;
  name: string;
  totalGranted: number;
  totalUsed: number;
  totalRemaining: number;
  endDate: string;
};

export type Account = {
  accountOrganizations: [AccountOrganization];
  createdAt: string;
  enabledFeatures: [string];
  id: string;
  name: string;
  ownerId: string;
  status: string;
  type: string;
  updatedAt: string;
};

export enum AccountType {
  account = 'account',
  business = 'business',
}

export interface LOAD_DATA_PROPS {
  additionalFilters?: ADDITIONAL_FILTERS_TYPE;
  filters: MAIN_FILTERS_TYPE;
  isLogicalAccount?: boolean;
  limit?: number;
  offset: number;
}

type Balance = {
  type: string;
  amount: number;
};

type CobSchedule = { cobTime: string; cobTimezone: string };

type FundingAgent = { default: boolean; status: string };

export interface AccountDataProps {
  enabledFeatures: string[];
  fundingAgents: FundingAgent[];
  cobSchedules: CobSchedule[];
  balances: Balance[];
  type: string;
}
