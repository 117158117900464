import InfoList from 'src/components/infoList/InfoList';
import { stringifyDate } from 'src/helpers/dateTimeHelpers';
import TransferStatusSwitcher from '../TransferStatusSwitcher';

export const PayoutAttrDefs = [
  {
    id: 'id',
    label: 'Payout ID',
  },
  {
    id: 'accountId',
    label: 'Account ID',
  },
  {
    id: 'currency',
    label: 'Currency',
  },
  {
    id: 'fundingAgentId',
    label: 'Funding Agent ID',
  },
  {
    id: 'ledgerCount',
    label: 'Ledger Count',
  },
  {
    id: 'payoutAmount',
    label: 'Payout Amount',
  },
  {
    id: 'payoutFee',
    label: 'Payout Fee',
  },
  {
    id: 'payoutTime',
    label: 'Payout Time',
    customRender: stringifyDate,
  },
  {
    id: 'sourceAccountId',
    label: 'Source Account ID',
  },
  {
    id: 'subTotalAmount',
    label: 'Subtotal Amount',
  },
  {
    id: 'transferId',
    label: 'Transfer ID',
  },
  {
    id: 'transferStatus',
    label: 'Transfer Status',
  },
  {
    id: 'createdAt',
    label: 'Created at',
    customRender: stringifyDate,
  },
  {
    id: 'updatedAt',
    label: 'Updated at',
    customRender: stringifyDate,
  },
];

export const TransferAttrDefs = [
  {
    id: 'id',
    label: 'Transfer ID',
  },
  {
    id: 'destinationId',
    label: 'Destination ID',
  },
  {
    id: 'destinationOwnerId',
    label: 'Destination Owner ID',
  },
  {
    id: 'destinationOwnerName',
    label: 'Destination Owner Name',
    maskValue: true,
  },
  {
    id: 'destinationOwnerType',
    label: 'Destination Owner Type',
  },
  {
    id: 'failureCode',
    label: 'Failure Code',
    hideIfEmpty: true,
  },
  {
    id: 'failureReason',
    label: 'Failure Reason',
    hideIfEmpty: true,
  },
  {
    id: 'returnCode',
    label: 'Return Code',
    hideIfEmpty: true,
  },
  {
    id: 'transferProvider',
    label: 'Transfer Provider',
  },
  {
    id: 'sourceId',
    label: 'Source ID',
  },
  {
    id: 'statementDescriptor',
    label: 'Statement Descriptor',
  },
  {
    id: 'transferType',
    label: 'Transfer Type',
  },
  {
    id: 'amount',
    label: 'Amount',
  },
  {
    id: 'currency',
    label: 'Currency',
  },
  {
    id: 'transferStatus',
    label: 'Transfer Status',
    customRender: (status: string, data: any, context: any) => {
      return <TransferStatusSwitcher context={context} data={data} status={status} />;
    },
  },
  {
    id: 'enabledFlags',
    label: 'Enabled Flags',
    hideIfEmpty: true,
    customRender: (item: string[] = []) => item.join(', '),
  },
  {
    id: 'createdAt',
    label: 'Created At',
    customRender: stringifyDate,
  },
  {
    id: 'effectiveAt',
    label: 'Effective at',
    customRender: stringifyDate,
  },
  {
    id: 'submittedAt',
    label: 'Submitted At',
    customRender: stringifyDate,
  },
  {
    id: 'scheduledAt',
    label: 'Scheduled At',
    customRender: stringifyDate,
  },
  {
    id: 'transferReportId',
    label: 'Transfer Report Id',
  },
  {
    id: 'transferBatchId',
    label: 'Transfer Batch Id',
  },
  {
    id: 'traceNumber',
    label: 'Trace Number',
  },
  {
    id: 'updatedAt',
    label: 'Updated at',
    customRender: stringifyDate,
  },
];

const BankAccountAttrDefs = [
  {
    id: 'routingNumber',
    label: 'Routing Number',
    maskValue: true,
  },
  {
    id: 'accountNumber',
    label: 'Account Number',
    hideIfEmpty: true,
    maskValue: true,
  },
  {
    id: 'accountNumberLastFour',
    label: 'Account Number`s Last 4 Digits',
  },
  {
    id: 'bankName',
    label: 'Bank Name',
  },
  {
    id: 'accountType',
    label: 'Account Type',
  },
  {
    id: 'country',
    label: 'Country',
  },
  {
    id: 'currency',
    label: 'Currency',
  },
];

export const FinInstrumentAttrDefs = [
  {
    id: 'id',
    label: 'ID',
  },
  {
    id: 'type',
    label: 'Type',
  },
  {
    id: 'fingerprint',
    label: 'Fingerprint',
  },
  {
    id: 'name',
    label: 'Name',
    maskValue: true,
  },
  {
    id: 'purpose',
    label: 'Purpose',
    customRender: (item: any = []) => item.join(', '),
  },
  {
    id: 'bankAccount',
    label: 'Bank Account',
    customRender: (item: any) => <InfoList data={item} attrDefinitions={BankAccountAttrDefs} />,
  },
  {
    id: 'status',
    label: 'Status',
  },
  {
    id: 'ownerId',
    label: 'Owner Id',
  },
  {
    id: 'ownerType',
    label: 'Owner Type',
  },
  {
    id: 'token',
    label: 'Token',
    hideIfEmpty: true,
  },
  {
    id: 'tokenSource',
    label: 'Token Source',
  },
  {
    id: 'createdAt',
    label: 'Created at',
    customRender: stringifyDate,
  },
  {
    id: 'updatedAt',
    label: 'Updated at',
    customRender: stringifyDate,
  },
];
