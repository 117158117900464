import { ApolloClient, from, HttpLink, InMemoryCache } from '@apollo/client';
import { get } from 'lodash';
import { onError } from '@apollo/client/link/error';

import logger from './../logger';
import { toastError, toastInfo } from 'src/helpers/toastHelpers';

const paginatedMergeFn = (existing: any, incoming: any, { args }: Record<string, any>) => {
  const offset = Number(args?.offset || 0);

  const existingNodes = existing?.nodes;
  const incomingNodes = incoming?.nodes;

  const mergedNodes = existingNodes ? existingNodes.slice(0) : [];

  for (let i = 0; i < incomingNodes.length; ++i) {
    mergedNodes[offset + i] = incomingNodes[i];
  }
  return {
    ...existing,
    ...incoming,
    nodes: mergedNodes,
  };
};

const paginatedReadFn = (existing: any, { args }: Record<string, any>) => {
  const existingNodes = existing?.nodes || [];
  const offset = Number(args?.offset || 0);
  const limit = Number(args?.limit || existingNodes.length);
  const updatedNodes = existingNodes?.slice(offset, offset + limit);

  return updatedNodes?.length ? { ...existing, nodes: updatedNodes } : undefined;
};

const httpLink = new HttpLink({
  uri: '/graphql',
  // TODO use the proper 'credentials' prop
  // use credentials: 'same-origin' if your backend server is the same domain
  // or else credentials: 'include' if your backend is a different domain.
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, path, extensions }) => {
      logger.debug(
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        `GraphQL Error, message: [${message}]; path: [${path}]; extensions: ${
          extensions ? JSON.stringify(extensions, null, ' ') : '{}'
        }`
      );

      const isNotFoundError = extensions?.code === 404;

      if (isNotFoundError) {
        toastError(message, {
          toastId: message,
        });

        return;
      }

      const unauthenticatedError = extensions?.code === 'UNAUTHENTICATED';
      const redirectUrl = get(extensions, 'redirectUrl', '') as string;

      if (unauthenticatedError && redirectUrl) {
        toastInfo('Redirecting to SSO Authentication Server...', {
          toastId: 'REDIRECTION_TOAST',
        });

        window.location.href = redirectUrl; // redirects to the SSO login page
        return;
      }

      toastError(message, { toastId: message });
    });

    return; // to avoid error duplication (the graphQL errors might be duplicated by a network error)
  }

  if (networkError) {
    const errorBody = get(networkError, 'bodyText', networkError);
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    const errorMessage = errorBody ? `[Network error]: ${errorBody}` : '[Unknown Network error]';

    toastError(errorMessage, { toastId: 'NETWORK_ERROR' });
    logger.error(errorMessage);
  }
});

export default new ApolloClient({
  link: from([errorLink, httpLink]),
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          accounts: {
            keyArgs: false,
            merge: paginatedMergeFn,
            read: paginatedReadFn,
          },
          accountLedgers: {
            keyArgs: ['id', 'reqIdType', 'filterString', 'limit', 'offset'],
            merge: paginatedMergeFn,
            read: paginatedReadFn,
          },
          accountPayouts: {
            keyArgs: ['id', 'reqIdType', 'limit', 'offset'],
            merge: paginatedMergeFn,
            read: paginatedReadFn,
          },
        },
      },
    },
  }),
});
