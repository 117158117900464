import { ReactElement } from 'react';

import DetailsWrapper from './DetailsWrapper';
import VisibilitySwitcher from 'src/components/common/VisibilitySwitcher/VisibilitySwitcher';
import { CopyToClipboardByIcon } from 'src/components/common/CopyToClipboard/CopyToClipboard';
import { DetailsRow } from '../InfoPanelLayout';
import { stringifyDate } from 'src/helpers/dateTimeHelpers';
import { TxnInfo } from '../../../../constants/txnConstants';

const DepositDetails = ({ txnData, itemId: depositId }: { txnData: TxnInfo; itemId: string | null }): ReactElement => {
  const deposit = txnData?.deposit;

  if (!deposit) {
    return (
      <DetailsWrapper>
        <DetailsRow label="No data found for the deposit" value={depositId} />
      </DetailsWrapper>
    );
  }

  return (
    <DetailsWrapper label="Deposit Details">
      <DetailsRow label="ID:" value={<CopyToClipboardByIcon text={deposit?.id} />} />
      <DetailsRow label="Amount:" value={deposit?.amount} />
      <DetailsRow label="Currency:" value={deposit?.currency} />
      <DetailsRow label="Acquirer:" value={deposit?.acquirer} />
      <DetailsRow label="Authorization Code:" value={deposit?.authorizationCode} />
      <DetailsRow label="Business Type:" value={deposit?.businessType} />
      <DetailsRow label="Card No:" value={<VisibilitySwitcher>{deposit?.cardNo}</VisibilitySwitcher>} />
      <DetailsRow label="Deposited at:" value={stringifyDate(deposit?.depositedAt)} />
      <DetailsRow label="Funding Id:" value={<CopyToClipboardByIcon text={deposit?.fundingId} />} />
      <DetailsRow label="Mid:" value={<CopyToClipboardByIcon text={deposit?.mid} />} />
      <DetailsRow label="Processor:" value={deposit?.processor} />
      <DetailsRow label="Store Id:" value={deposit?.storeId} />
    </DetailsWrapper>
  );
};

export default DepositDetails;
