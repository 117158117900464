import { get, map } from 'lodash';
import { styled } from '@mui/material';

import Tooltip from '@mui/material/Tooltip';
import InfoIcon from '@mui/icons-material/Info';

import { stringifyDate } from 'src/helpers/dateTimeHelpers';

export const MAXIMUM_HISTORY_ITEMS = 100;

export const accountValidationTooltipMapper = {
  bav_credit_failed: <span>The bank account validation with a debit attempt failed</span>,
  bav_debit_failed: <span>the bank account validation with a credit attempt failed</span>,
};

export const returnReasonTooltipMapper = {
  RDFI_NOT_QUALIFIED: (
    <span>
      <strong>RDFI_NOT_QUALIFIED</strong>: RDFI stands for Receiving Depository Financial Institution. This return code
      means that the underlying financial institution is not qualified to participate the ACH network.
    </span>
  ),
  ODFI_VALIDACTION_FAILED: (
    <span>
      <strong>ODFI_VALIDACTION_FAILED</strong>: ODFI stands for Originating Depository Financial Institutions. In this
      case, the ODFI is revoking the authorization previously granted on the transfer.
    </span>
  ),
  PAYMENT_STOPPED: (
    <span>
      <strong>PAYMENT_STOPPED</strong>: this is a general category to indicate that the transaction stopped from being
      processed (usually due to error such as incorrect entry).
    </span>
  ),
  NON_TRANSACTION_ACCOUNT: (
    <span>
      <strong>NON_TRANSACTION_ACCOUNT</strong>: as indicated by the reason, the account does not allow ACH payments.
      Examples of non-transaction account include savings deposits, money market deposit accounts.
    </span>
  ),
  RETURNED_PER_ODFI_REQUEST: (
    <span>
      <strong>RETURNED_PER_ODFI_REQUEST</strong>: ODFI stands for Originating Depository Financial Institutions. This
      return reason means the return is requested by the originating party.
    </span>
  ),
};

export const bankAccountHistoryColumnsConfig = [
  {
    attr: 'bankName',
    label: 'Bank Name',
  },
  {
    attr: 'routingNumber',
    label: 'Routing Number',
  },
  {
    attr: 'accountNumberLast4',
    label: 'Account Number',
    customRenderer: (rowItem: Record<string, any>) =>
      rowItem.accountNumberLast4 ? `**** ${rowItem.accountNumberLast4}` : '',
  },
  {
    attr: 'status',
    label: 'Status',
  },
  {
    attr: 'createdAt',
    label: 'Date of Bank Account Added',
    customRenderer: (item: Record<string, any>) => stringifyDate(get(item, 'createdAt')),
  },
  {
    attr: 'accountValidationResult',
    label: 'Account Validation Result',
    customRenderer: (rowItem: Record<string, any>) =>
      map(get(rowItem, 'accountValidationResult', '').split(/\s*,\s*/), result => {
        const tooltip = get(accountValidationTooltipMapper, result, '');

        return (
          <div>
            {tooltip ? (
              <Tooltip title={<TooltipContainer>{tooltip}</TooltipContainer>} placement="bottom">
                <TooltipContent>{result}</TooltipContent>
              </Tooltip>
            ) : (
              result
            )}
          </div>
        );
      }),
    customColumnRenderer: (label: string) => (
      <Tooltip
        title={
          <TooltipContainer>
            <strong>Account Validation Result</strong> has two possible values. Move the cursor over a value to get its
            explanation.
          </TooltipContainer>
        }
        placement="bottom"
      >
        <TooltipContent>
          {label}&nbsp;
          <InfoIcon fontSize="small" />
        </TooltipContent>
      </Tooltip>
    ),
  },
  {
    attr: 'returnCode',
    label: 'Return Reason',
    customRenderer: (rowItem: Record<string, any>) =>
      map(get(rowItem, 'returnCode', '').split(/\s*,\s*/), item => {
        const tooltip = get(returnReasonTooltipMapper, item, '');

        return (
          <div>
            {tooltip ? (
              <Tooltip title={<TooltipContainer>{tooltip}</TooltipContainer>} placement="bottom">
                <TooltipContent>{item}</TooltipContent>
              </Tooltip>
            ) : (
              item
            )}
          </div>
        );
      }),
    customColumnRenderer: (label: string) => (
      <Tooltip
        title={
          <TooltipContainer>
            <strong>Return Reason</strong> explains why the bank account validation failed. Most return reasons are
            self-explanatory (e.g., insufficient fund, account not found), but you can find explanations for the ones
            that less straightforward in the footnotes or by hovering over the values.
          </TooltipContainer>
        }
        placement="bottom"
      >
        <TooltipContent>
          {label}&nbsp;
          <InfoIcon fontSize="small" />
        </TooltipContent>
      </Tooltip>
    ),
  },
];

const TooltipContainer = styled('p')`
  padding: 0;
  margin: 0;
  font-size: 13px;
`;

const TooltipContent = styled('span')`
  &:hover {
    cursor: default;
  }
`;
