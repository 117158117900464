import { ChangeEvent, ReactElement } from 'react';
import Button from '@mui/material/Button';
import UploadFileIcon from '@mui/icons-material/UploadFile';

import CircularLoader from 'src/components/progress/CircularLoader';
import { AggregatedReportType } from 'src/containers/aggregatedReports/constants/reportConstants';
import { useUploader } from 'src/containers/aggregatedReports/hooks/useUploader';

interface FileUploaderProps {
  aggregatedReportType: AggregatedReportType;
  label?: string;
}

const FileUploader = ({ aggregatedReportType, label = 'Upload CSV' }: FileUploaderProps): ReactElement => {
  const { uploadFile, loading } = useUploader(aggregatedReportType);
  const handleFileUpload = async (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) {
      return;
    }

    const file = e?.target?.files[0];

    uploadFile(file);

    if (loading) {
      return <CircularLoader />;
    }
  };

  return (
    <Button variant="outlined" startIcon={<UploadFileIcon />} component="label">
      {label}
      <input type="file" accept=".csv" hidden onChange={handleFileUpload} />
    </Button>
  );
};

export default FileUploader;
