import DialogContentText from '@mui/material/DialogContentText';

import CommonAdjustments, { AdjusterProps } from './CommonAdjustment';
import { DEBIT_ADJUSTMENTS_MUTATION } from 'src/services/apollo/mutations/ledgerMutations';

const DebitAdjustments = ({ disabled, filters }: AdjusterProps) => {
  const description = (
    <DialogContentText>
      You can use this form to create a debit entry to a merchant’s ledger balance for various purposes (e.g.,
      inaccurate pricing, missed fees, etc).
      <br />
      Please input a positive value <strong>(in dollars not cents)</strong> in the “Amount” field; the amount will be a
      debit (i.e., deduction) to the merchant’s current balance.
    </DialogContentText>
  );

  return (
    <CommonAdjustments
      action="adjustDebit"
      actionMutation={DEBIT_ADJUSTMENTS_MUTATION}
      description={description}
      disabled={disabled}
      filters={filters}
      title="Debit Adjustments"
    />
  );
};

export default DebitAdjustments;
