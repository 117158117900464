import styled from '@emotion/styled';
import { map, isEmpty } from 'lodash';

import ChildTransaction from './ChildTransaction';
import { TxnInfo } from '../../../../constants/txnConstants';

const RelatedTransactions = ({ baseTxnId, data }: { baseTxnId: string; data?: TxnInfo[] }) => {
  if (isEmpty(data)) {
    return null;
  }

  return (
    <RelatedTransactionsWrapper>
      <RelatedTransactionsContent>
        {map(data, txnInfo => (
          <ChildTransaction key={txnInfo.id} txnInfo={txnInfo} isActive={txnInfo.id === baseTxnId} />
        ))}
      </RelatedTransactionsContent>
    </RelatedTransactionsWrapper>
  );
};

const RelatedTransactionsWrapper = styled('div')`
  margin-top: 8px;
  overflow: hidden;
  padding-left: 8px;
`;

const RelatedTransactionsContent = styled('div')`
  margin-bottom: 60px;
  margin-top: -68px;
`;

export default RelatedTransactions;
