import { gql } from '@apollo/client';

export const USER_INFO_QUERY = gql`
  query userInfo {
    userInfo {
      id
      isAuthenticated
      firstName
      lastName
      email
      role
      userAbilities {
        rule
        action
        scope
      }
    }
  }
`;
