import { gql } from '@apollo/client';

export const TXN_LOOKUP_QUERY = gql`
  query txnLookup($txnId: ID!) {
    txnLookup(txnId: $txnId) {
      accountData {
        id
        name
        status
        type
        balances {
          type
          amount
        }
        cobSchedules {
          createdAt
          updatedAt
        }
        enabledFeatures
        fundingAgents {
          id
          default
          status
        }
      }
      transactionData {
        baseTxn {
          action
          amount
          createdAt
          id
          isSettled
          ledgeredAt
          status
          deposit {
            id
            storeId
            amount
            currency
            depositedAt
            cardNo
            authorizationCode
            mid
            acquirer
            processor
            businessType
            fundingId
          }
          ledgers {
            amount
            createdAt
            currency
            id
            ledgeredAt
            notes
            payoutId
            refId
            refType
            sourceAccount
            type
            updatedAt
            payout {
              id
              createdAt
              currency
              fundingAgentId
              ledgerCount
              netLedgerAmount
              payoutAmount
              payoutFee
              payoutTime
              subTotalAmount
              transferId
              transferRequestId
              transferStatus
              updatedAt
            }
          }
        }
        parentTxn {
          action
          amount
          createdAt
          id
          isSettled
          ledgeredAt
          status
        }
        childTxns {
          action
          amount
          createdAt
          id
          isSettled
          ledgeredAt
          status
        }
      }
    }
  }
`;
