import { useEffect, useState } from 'react';
import { useApolloClient } from '@apollo/client';

import { TXN_LOOKUP_QUERY } from 'src/services/apollo/queries/txnQueries';
import { useGlobalProgressBar } from 'src/context/GlobalProgressBarContext';
import { FullTxnData } from '../constants/txnConstants';

export default function useTxnData() {
  const [txnData, setTxnData] = useState<FullTxnData | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState(null);

  const { setIsLoading } = useGlobalProgressBar();

  useEffect(() => {
    setIsLoading(loading);

    return () => {
      setIsLoading(false);
    };
  }, [loading, setIsLoading]);

  const apolloClient = useApolloClient();

  const getTxnData = async (txnId: string) => {
    try {
      setLoading(true);

      const { data } = await apolloClient.query({
        query: TXN_LOOKUP_QUERY,
        variables: { txnId },
      });

      data && setTxnData(data.txnLookup);
    } catch (e) {
      setError(e);
    } finally {
      setLoading(false);
    }
  };

  return { data: txnData, error, isLoading: loading, getTxnData };
}
