import { isEmpty } from 'lodash';
import { ReactElement } from 'react';
import { styled } from '@mui/material';

import CircularLoader from 'src/components/progress/CircularLoader';
import FileList from 'src/components/fileList/FileList';
import FileUploader from 'src/components/fileUploader/FileUploader';

import { AggregatedReportType } from '../constants/reportConstants';
import { FilledPaddedContainer, SectionHeader } from 'src/components/layouts/Layout';
import { triggerFileDownload } from 'src/helpers/fileHelpers';
import { useReportContent } from 'src/containers/aggregatedReports/hooks/useReportContent';
import { useReportList } from 'src/containers/aggregatedReports/hooks/useReportList';

interface CommonReportType {
  label: string;
  reportType: AggregatedReportType;
}

const CommonReport = ({ label = 'Upload CSV', reportType }: CommonReportType): ReactElement => {
  const { files, loading: isReportListLoading } = useReportList(reportType);
  const { getReportContent, loading: isReportFileLoading } = useReportContent(reportType);

  const onReportClick = async (fileName: string) => {
    const reportContent = await getReportContent(fileName);

    if (!reportContent) {
      return;
    }

    triggerFileDownload(fileName, reportContent);
  };

  if (isReportListLoading) {
    return <CircularLoader />;
  }

  const content = isEmpty(files) ? <>No Reports Found</> : <FileList files={files} onFileClick={onReportClick} />;

  return (
    <FilledPaddedContainer>
      <ReportHeader>
        <SectionHeader>List of Reports</SectionHeader>
        <ActionContainer>
          <FileUploader aggregatedReportType={reportType} label={label} />
        </ActionContainer>
      </ReportHeader>
      {isReportFileLoading ? <CircularLoader /> : null}
      {content}
    </FilledPaddedContainer>
  );
};

const ActionContainer = styled('div')`
  align-self: center;
`;

const ReportHeader = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export default CommonReport;
