import styled from '@emotion/styled';
import { get } from 'lodash';
import { useQuery } from '@apollo/client';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import PriceProfileInfo from './PriceProfileInfo';
import { PriceProfileLabel, PriceProfileValue, PriceProfileWrapper } from './CommonLayout';
import { PRICE_PROFILE_INFO } from 'src/services/apollo/queries/pricingQueries';
import { PricingProfile } from '../constants/pricingProfileConstants';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

type ConfirmationDialogProps = {
  businessId: string;
  businessLabel: string;
  handleClose: () => void;
  handleSubmit: () => void;
  isLoading: boolean;
  currentPriceProfileId: string;
  selectedPriceProfileId: string;
};

const PriceProfileChangeConfirmation = ({
  businessLabel,
  handleClose,
  handleSubmit,
  isLoading,
  currentPriceProfileId,
  selectedPriceProfileId,
}: ConfirmationDialogProps) => {
  const { data: priceProfileResponse, loading: priceProfileIsLoading } = useQuery(PRICE_PROFILE_INFO, {
    variables: { priceProfileId: selectedPriceProfileId },
  });

  const currentPriceProfile = get(priceProfileResponse, 'priceProfileInfo', {}) as PricingProfile;

  return (
    <Dialog fullWidth open={true} onClose={handleClose}>
      <Backdrop sx={{ color: '#ccc', zIndex: 100 }} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <DialogTitle>Are you sure?</DialogTitle>
      <DialogContent sx={{ minWidth: 300 }}>
        <Box sx={{ my: 3 }}>
          <Box sx={{ my: 3 }}>
            Please confirm price profile change for <HighlightedText>{businessLabel.toUpperCase()}.</HighlightedText>
            <br />
          </Box>
          <PriceProfileWrapper>
            <PriceProfileLabel>Current price profile ID</PriceProfileLabel>
            <PriceProfileValue>{currentPriceProfileId}</PriceProfileValue>
          </PriceProfileWrapper>
          <Box sx={{ my: 2 }}>
            <Box sx={{ my: 3 }}>change to:</Box>
            <PriceProfileInfo priceProfileData={currentPriceProfile} priceProfileIsLoading={priceProfileIsLoading} />
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button disabled={!selectedPriceProfileId || priceProfileIsLoading} onClick={handleSubmit}>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const HighlightedText = styled('span')`
  color: #3b3b3b;
  font-weight: 600;
`;

export default PriceProfileChangeConfirmation;
