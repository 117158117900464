import ReactFlow from 'reactflow';
import styled from '@emotion/styled';

import 'reactflow/dist/style.css';

import DepositNode from './nodes/DepositNode';
import LedgerNode from './nodes/LedgerNode';
import PayoutNode from './nodes/PayoutNode';
import RootNode from './nodes/RootNode';
import { initializeNodes } from '../../helpers/txnLookupHelpers';
import { TxnInfo } from '../../constants/txnConstants';

const nodeTypes = { rootNode: RootNode, ledgerNode: LedgerNode, payoutNode: PayoutNode, depositNode: DepositNode };

const TxnGraph = ({ txnData }: { txnData: TxnInfo }) => {
  const { nodes, edges } = initializeNodes(txnData);

  return (
    <ReactFlowWrapper>
      <ReactFlow edges={edges} nodes={nodes} nodeTypes={nodeTypes} zoomOnScroll={false} />
    </ReactFlowWrapper>
  );
};

const ReactFlowWrapper = styled('div')`
  align-self: stretch;
  background-color: #fff;
  border: 1px solid #ccc;
  display: flex;
  height: 800px;
  margin-top: 130px;
`;

export default TxnGraph;
