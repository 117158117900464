import { defineAbility } from '@casl/ability';
import { map } from 'lodash';

import logger from 'src/services/logger';
import { Rules, UserAbility } from '../constants/userConstants';
import { toastError } from './toastHelpers';

export const getAbilityFor = (userAbilities: UserAbility[]) => {
  return defineAbility((can, cannot) => {
    map(userAbilities, userAbility => {
      const { rule, action, scope } = userAbility;
      const unknownRule = ![Rules.Can, Rules.Cannot].includes(rule);

      if (unknownRule) {
        const errorMessage = `Unknown ability "${rule}" in the User permissions`;

        logger.error(errorMessage);
        toastError('Error while parsing user permissions', {
          toastId: 'abilityError',
        });

        return;
      }

      const ability = rule === Rules.Can ? can : cannot;

      ability(action, scope);
    });
  });
};
