import { FC, SyntheticEvent } from 'react';
import { Autocomplete, AutocompleteRenderGetTagProps, Chip, TextField } from '@mui/material';
import { Controller } from 'react-hook-form';
import { map } from 'lodash';
import { styled } from '@mui/material/styles';

import { FormFieldType } from './../formTypes';

interface MultipleChipsType {
  label: string;
  onChange: (value: string | string[]) => void;
  placeholder?: string;
  value: string[];
}

interface MultipleChipsFieldType extends FormFieldType {
  defaultValue?: string[];
  label: string;
  placeholder?: string;
}

const MultipleChips: FC<MultipleChipsType> = props => {
  const { label, onChange, placeholder = '', value } = props;

  const onTagChange = (e: SyntheticEvent, value: string | string[]) => {
    onChange(value);
  };

  return (
    <Autocomplete
      multiple
      onChange={onTagChange}
      options={[]}
      freeSolo
      renderTags={(value: string[], getTagProps: AutocompleteRenderGetTagProps) =>
        map(value, (option: string, index: number) => {
          return <Chip variant="outlined" label={option} {...getTagProps({ index })} />;
        })
      }
      renderInput={params => <TextField {...params} label={label} placeholder={placeholder} />}
      value={value}
    />
  );
};

const MultipleChipsField: FC<MultipleChipsFieldType> = ({ name, control, label }) => {
  return (
    <MultipleChipsFieldWrapper>
      <Controller
        control={control}
        name={name}
        render={({ field: { onChange, value } }) => {
          return <MultipleChips label={label} onChange={onChange} value={value} />;
        }}
      />
    </MultipleChipsFieldWrapper>
  );
};

const MultipleChipsFieldWrapper = styled('div')`
  margin-bottom: 8px;
`;

MultipleChipsFieldWrapper.displayName = 'MultipleChipsFieldWrapper';

export default MultipleChipsField;
