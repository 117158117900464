import { ReactElement } from 'react';

import DetailsWrapper from './DetailsWrapper';
import { CopyToClipboardByIcon } from 'src/components/common/CopyToClipboard/CopyToClipboard';
import { DetailsProps } from '../constants/infoPanelConstants';
import { DetailsRow, DetailsUrlWrapper, InfoPanelWrapper } from '../InfoPanelLayout';
import { getAccountUrlPrefix } from 'src/helpers/commonHelpers';
import { InternalPageLink } from 'src/components/layouts/Layout';
import { stringifyDate } from 'src/helpers/dateTimeHelpers';

const LedgerDetails = ({ accountId, txnData, itemId: ledgerId }: DetailsProps): ReactElement => {
  const ledgers = txnData?.ledgers || [];
  const ledger = ledgers.find(({ id }) => id === ledgerId);

  if (!ledger) {
    return (
      <InfoPanelWrapper>
        <DetailsRow label="No data found for the ledger" value={ledgerId} />
      </InfoPanelWrapper>
    );
  }

  const ledgerUrl =
    accountId && ledgerId ? (
      <InternalPageLink
        to={`${getAccountUrlPrefix(accountId)}/ledger/${encodeURIComponent(ledgerId)}`}
        rel="noopener"
        target="_blank"
      >
        See full ledger details
      </InternalPageLink>
    ) : (
      ''
    );

  return (
    <DetailsWrapper label="Ledger Details">
      <DetailsRow label="ID:" value={<CopyToClipboardByIcon text={ledger?.id} />} />
      <DetailsRow label="Amount:" value={ledger?.amount} />
      <DetailsRow label="Type:" value={ledger?.type} />
      <DetailsRow label="Created at:" value={stringifyDate(ledger?.createdAt)} />
      <DetailsRow label="Currency:" value={ledger?.currency} />
      <DetailsRow label="Ledgered at:" value={stringifyDate(ledger?.ledgeredAt)} />
      <DetailsRow label="Notes:" value={ledger?.notes} />
      <DetailsRow label="Ref ID:" value={<CopyToClipboardByIcon text={ledger?.refId} />} />
      <DetailsRow label="Ref type:" value={ledger?.refType} />
      <DetailsRow label="Source Account:" value={ledger?.sourceAccount} />
      <DetailsRow label="Updated at:" value={ledger?.updatedAt} />
      <DetailsUrlWrapper>{ledgerUrl}</DetailsUrlWrapper>
    </DetailsWrapper>
  );
};

export default LedgerDetails;
