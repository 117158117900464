import { isString, map } from 'lodash';
import { DropdownItemType } from './../components/form/formTypes';

export const getOptions = (items: Array<string> | Record<number, string>, withNone?: boolean): DropdownItemType[] => {
  return [...(withNone ? [{ label: 'None', value: '' }] : []), ...map(items, v => ({ label: v, value: v }))];
};

type OBJECT_TYPE = Record<string, any>;

export const buildAttrDefsFromObject = (data: OBJECT_TYPE) => {
  return map(data, (_, key) => ({ id: key, label: key }));
};

export const capitalizeFirstLetter = (stringToProcess: string): string =>
  isString(stringToProcess) ? stringToProcess.charAt(0).toUpperCase() + stringToProcess.slice(1) : stringToProcess;

export const getAccountUrlPrefix = (accountId = '') => {
  const isAccountType = accountId.substring(0, 7) === 'urn:acc';

  return `/account/${encodeURIComponent(accountId)}/type/${isAccountType ? 'account' : 'business'}`;
};

export const convertDecimalsToCents = (amount: string) => Math.round(+amount * 100);
