import { get } from 'lodash';
import { Paper, styled } from '@mui/material';
import { ReactElement } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';

import CommonViewer from 'src/components/viewer/CommonViewer';
import InfoList from 'src/components/infoList/InfoList';
import LedgerPayoutInfo from './components/LedgerPayoutInfo';
import { Header } from 'src/components/layouts/Layout';
import { LedgerAttrDefs } from './constants/singleLedgerConstants';
import { SINGLE_LEDGER_QUERY } from 'src/services/apollo/queries/accountQueries';

interface SingleAccountProps {
  goBackUrl?: string;
}

const SingleLedger = ({ goBackUrl }: SingleAccountProps): ReactElement => {
  const urlParams = useParams();
  const accountId = decodeURIComponent(get(urlParams, 'accountId', ''));
  const requestIdType = decodeURIComponent(get(urlParams, 'reqIdType', ''));
  const ledgerId = decodeURIComponent(get(urlParams, 'ledgerId', ''));

  const { loading: isLoading, data } = useQuery(SINGLE_LEDGER_QUERY, {
    variables: {
      accountId,
      requestIdType,
      ledgerId,
    },
    errorPolicy: 'all', // show partial data even if nested resolvers get errors
  });

  const ledgerData = data?.singleLedger;
  const ledgerHasPayout = !!ledgerData?.payoutId;

  const content = (
    <ContentWrapper>
      <LedgerInfoContainer>
        <Header>Ledger Info</Header>
        <InfoList attrDefinitions={LedgerAttrDefs} data={ledgerData} />
      </LedgerInfoContainer>
      {ledgerHasPayout ? (
        <LedgerPayoutInfo accountId={accountId} requestIdType={requestIdType} payoutId={ledgerData?.payoutId} />
      ) : null}
    </ContentWrapper>
  );

  return <CommonViewer goBackUrl={goBackUrl} isLoading={isLoading} content={content} />;
};

const ContentWrapper = styled('div')`
  display: flex;
  min-width: 500px;
  padding-top: 48px;
  width: 100%;
`;

ContentWrapper.displayName = 'ContentWrapper';

const LedgerInfoContainer = styled(Paper)`
  margin-right: 48px;
  min-width: 500px;
  padding: 0 24px 24px;
  width: 50%;
`;

LedgerInfoContainer.displayName = 'LedgerInfoContainer';

const PayoutInfoContainer = styled('div')`
  margin-right: 48px;
  min-width: 500px;
  padding: 0 24px 24px;
  width: 50%;
`;

PayoutInfoContainer.displayName = 'PayoutInfoContainer';

export default SingleLedger;
