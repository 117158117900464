import { SetStateAction, useEffect, useState } from 'react';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import styled from 'styled-components';

import { useGlobalProgressBar } from 'src/context/GlobalProgressBarContext';

interface GlobalProgressBarProps {
  intervalTime?: number;
}

export function GlobalProgressBar({ intervalTime = 50 }: GlobalProgressBarProps) {
  const [progress, setProgress] = useState(-1);
  const [timer, setTimer] = useState<SetStateAction<any>>(null);

  const { isLoading } = useGlobalProgressBar();

  const runTimer = () => {
    if (!timer) {
      setTimer(
        setInterval(() => {
          setProgress(prevProgress => {
            if (prevProgress === 100 || prevProgress === -1) {
              return 0;
            }

            const diff = Math.random() * intervalTime;

            return Math.min(prevProgress + diff, 100);
          });
        }, 500) as SetStateAction<any>
      );
    }
  };

  const clearTimer = () => {
    if (timer) {
      clearInterval(timer);
      setTimer(null);
    }
  };

  useEffect(() => {
    if (!isLoading && !timer) {
      return;
    }

    if (isLoading) {
      runTimer();
    } else {
      setProgress(100);
      setTimeout(() => setProgress(-1), 250);
      clearTimer();
    }

    return () => {
      setProgress(-1);
      clearTimer();
    };


  }, [isLoading]);

  return progress > -1 ? <StyledLinearProgress variant="determinate" value={progress} /> : null;
  // return <StyledLinearProgress variant="determinate" value={50} />;
}

const StyledLinearProgress = styled(LinearProgress)`
  &.${linearProgressClasses.root} {
    background: none;
    height: 3px;
    left: 0;
    opacity: 1;
    top: 0;
    position: absolute;
    width: 100%;
    z-index: 101;
  }
  
  .${linearProgressClasses.bar} {
    background-color: red !important;
  }
},
`;

StyledLinearProgress.displayName = 'StyledLinearProgress';
