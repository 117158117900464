import { gql } from '@apollo/client';

export const SINGLE_ACCOUNT_QUERY = gql`
  query singleAccount($id: ID!, $requestIdType: RequestIdType) {
    singleAccount(id: $id, requestIdType: $requestIdType) {
      accountOrganizations {
        accountId
        createdAt
        ownerId
        ownerType
        updatedAt
      }
      balances {
        type
        amount
        currency
      }
      cobSchedules {
        monday
        mondayFundingType
        mondayFundingValue
        tuesday
        tuesdayFundingType
        tuesdayFundingValue
        wednesday
        wednesdayFundingType
        wednesdayFundingValue
        thursday
        thursdayFundingType
        thursdayFundingValue
        friday
        fridayFundingType
        fridayFundingValue
        saturday
        saturdayFundingType
        saturdayFundingValue
        sunday
        sundayFundingType
        sundayFundingValue
        timezone
        currency
        createdAt
        updatedAt
      }
      createdAt
      enabledFeatures
      fundingAgents {
        default
        id
        accountId
        financialInstrumentId
        type
        status
        createdAt
        updatedAt
      }
      id
      name
      ownerId
      status
      type
      updatedAt
    }
  }
`;

export const BANK_ACCOUNT_HISTORY_QUERY = gql`
  query bankAccountHistory($id: ID!, $requestIdType: RequestIdType) {
    bankAccountHistory(id: $id, requestIdType: $requestIdType) {
      bankName
      accountNumberLast4
      accountType
      createdAt
      routingNumber
      status
      accountValidationResult
      returnCode
    }
  }
`;

export const BANK_ACCOUNTS_QUERY = gql`
  query bankAccounts($id: ID!) {
    bankAccounts(id: $id) {
      id
      accountNumberLastFour
      bankName
      finInstrumentShortId
      isDefault
    }
  }
`;

export const ACCOUNT_LEDGERS_QUERY = gql`
  query accountLedgersList(
    $id: ID!
    $requestIdType: RequestIdType!
    $isLogicalAccount: Boolean
    $filterString: String
    $offset: Int!
    $limit: Int!
  ) {
    accountLedgers(
      id: $id
      requestIdType: $requestIdType
      isLogicalAccount: $isLogicalAccount
      filterString: $filterString
      offset: $offset
      limit: $limit
    ) {
      nodes {
        accountId
        amount
        availableBalance
        createdAt
        currency
        id
        ledgeredAt
        payoutId
        refId
        refType
        sourceAccount
        type
        updatedAt
      }
      count
    }
  }
`;

export const SINGLE_LEDGER_QUERY = gql`
  query singleLedger($accountId: ID!, $requestIdType: RequestIdType, $ledgerId: ID!) {
    singleLedger(accountId: $accountId, requestIdType: $requestIdType, ledgerId: $ledgerId) {
      accountId
      arnInfo(id: $accountId, requestIdType: $requestIdType) {
        arns
      }
      amount
      availableBalance
      createdAt
      currency
      id
      ledgeredAt
      notes
      parentId
      parentType
      payoutId
      refId
      refType
      sourceAccount
      type
      updatedAt
    }
  }
`;

export const ACCOUNT_BALANCES_QUERY = gql`
  query accountBalancesList($accountId: ID!) {
    accountBalances(accountId: $accountId) {
      accountId
      type
      amount
      currency
      createdAt
      updatedAt
    }
  }
`;

export const ACCOUNT_PAYOUTS_QUERY = gql`
  query accountPayoutsList($id: ID!, $requestIdType: RequestIdType, $offset: Int!, $limit: Int!) {
    accountPayouts(id: $id, requestIdType: $requestIdType, offset: $offset, limit: $limit) {
      nodes {
        id
        effectiveAt
        payoutAmount
        payoutTime
        subTotalAmount
        currency
        transferId
        transferStatus
      }
      count
    }
  }
`;

export const SINGLE_ACCOUNT_PAYOUT_QUERY = gql`
  query singleAccountPayout($accountId: ID!, $requestIdType: RequestIdType, $payoutId: ID!) {
    singleAccountPayout(accountId: $accountId, requestIdType: $requestIdType, payoutId: $payoutId) {
      accountId
      createdAt
      currency
      fundingAgentId
      id
      ledgerCount
      payoutAmount
      payoutFee
      payoutTime
      sourceAccountId
      subTotalAmount
      transferId
      transferRequestId
      transferStatus
      updatedAt
      singleTransfer {
        amount
        createdAt
        currency
        destinationId
        destinationOwnerId
        destinationOwnerName
        destinationOwnerType
        effectiveAt
        id
        failureCode
        failureReason
        returnCode
        scheduledAt
        sourceId
        statementDescriptor
        submittedAt
        traceNumber
        transferBatchId
        transferProvider
        transferReportId
        transferStatus
        enabledFlags
        transferType
        updatedAt
        financialInstrument {
          createdAt
          fingerprint
          id
          name
          ownerId
          ownerType
          purpose
          status
          token
          tokenSource
          type
          updatedAt
          bankAccount {
            accountNumber
            accountNumberLastFour
            accountType
            bankName
            country
            currency
            routingNumber
          }
        }
      }
    }
  }
`;
