import { gql } from '@apollo/client';

export const DEBIT_ADJUSTMENTS_MUTATION = gql`
  mutation adjustDebit(
    $id: ID!
    $requestIdType: RequestIdType
    $refId: ID!
    $amount: Int!
    $currency: String!
    $notes: String
  ) {
    adjustDebit(
      id: $id
      requestIdType: $requestIdType
      refId: $refId
      amount: $amount
      currency: $currency
      notes: $notes
    ) {
      ledgerId
    }
  }
`;

export const CREDIT_ADJUSTMENTS_MUTATION = gql`
  mutation adjustCredit(
    $id: ID!
    $requestIdType: RequestIdType!
    $refId: ID!
    $amount: Int!
    $currency: String!
    $notes: String
  ) {
    adjustCredit(
      id: $id
      requestIdType: $requestIdType
      refId: $refId
      amount: $amount
      currency: $currency
      notes: $notes
    ) {
      ledgerId
    }
  }
`;

export const CREATE_PAYOUT_FROM_LEDGERS_MUTATION = gql`
  mutation createPayoutFromLedgers(
    $id: ID!
    $currency: String!
    $requestIdType: RequestIdType!
    $ledgers: [String!]!
    $bankAccountId: String
  ) {
    createPayoutFromLedgers(
      id: $id
      currency: $currency
      requestIdType: $requestIdType
      ledgers: $ledgers
      bankAccountId: $bankAccountId
    ) {
      payoutId
    }
  }
`;
