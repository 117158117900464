import { useQuery } from '@apollo/client';
import { get } from 'lodash';

import { BANK_ACCOUNTS_QUERY } from 'src/services/apollo/queries/accountQueries';

const useBankAccounts = (accountId: string) => {
  const { loading, data } = useQuery(BANK_ACCOUNTS_QUERY, { variables: { id: accountId } });

  const bankAccounts = get(data, 'bankAccounts', []);

  return { bankAccounts, isLoading: loading };
};

export default useBankAccounts;
