import { FC } from 'react';
import { Controller } from 'react-hook-form';

import DateTimePicker from 'src/components/common/DateTiimePicker/DateTimePicker';
import { LabeledFormFieldType } from './../formTypes';

const DateTimePickerField: FC<LabeledFormFieldType> = ({ control, label, name }) => {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onBlur, onChange, value } }) => (
        <DateTimePicker onBlur={onBlur} onChange={onChange} label={label} value={value} />
      )}
    />
  );
};

export default DateTimePickerField;
