import { MouseEvent, useEffect, useState } from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import styled from 'styled-components';
import { get } from 'lodash';
import { Menu, MenuItem } from '@mui/material';

import apmService from 'src/services/apmService';
import SignOutIcon from 'src/assets/svg/sign_out.svg';
import useUserData from 'src/context/UserContext';
import { ROLE_LABEL } from '../../constants/userConstants';

export default function UserInfoContainer() {
  const {
    user: { email, firstName, lastName, isAuthenticated, role = '' },
    signOut,
  } = useUserData();

  const [abbreviation, setAbbreviation] = useState<string>('');
  const [anchorElement, setAnchorElement] = useState<null | HTMLElement>(null);

  useEffect(() => {
    if (firstName && lastName) {
      setAbbreviation(`${firstName[0]}${lastName[0]}`);
    }

    if (email && (firstName || lastName)) {
      const username = `${firstName ? `${firstName} ` : ''}${lastName ? lastName : ''}`;

      apmService.setUserContext({ email, username });
    }
  }, [firstName, lastName, email]);

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorElement(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorElement(null);
  };

  const signOutHandler = () => {
    signOut();
    handleClose();
  };

  const userRole = get(ROLE_LABEL, role, role);

  return !isAuthenticated ? null : (
    <StyledUserInfoContainer data-aid="user-info-container">
      <Button aria-controls="user-menu" aria-haspopup="true" onClick={handleClick} data-aid="user-info-button">
        <Avatar data-aid="user-info-avatar">{abbreviation}</Avatar>
      </Button>
      <StyledRootMenu
        id="user-menu"
        anchorEl={anchorElement}
        keepMounted
        open={Boolean(anchorElement)}
        onClose={handleClose}
      >
        <StyledMenuItem onClick={handleClose}>
          <UserInfoSection>
            <User>
              <Avatar>
                <span>{abbreviation}</span>
              </Avatar>
              <UserData>
                <UserName>
                  {firstName} {lastName}
                </UserName>
                <UserEmail>{email}</UserEmail>
              </UserData>
            </User>
            <UserRole>{userRole}</UserRole>
          </UserInfoSection>
        </StyledMenuItem>
        <SignOutMenuItem onClick={signOutHandler}>
          <SignOutImg src={SignOutIcon} alt="Sign Out" />
          <SignOutText>Sign out</SignOutText>
        </SignOutMenuItem>
      </StyledRootMenu>
    </StyledUserInfoContainer>
  );
}

const StyledUserInfoContainer = styled.div`
  display: flex;
  alignitems: center;
`;

StyledUserInfoContainer.displayName = 'StyledUserInfoContainer';

const StyledRootMenu = styled(Menu)`
  width: auto;

  ul {
    padding: 0;
  }
`;

StyledRootMenu.displayName = 'StyledRootMenu';

const StyledMenuItem = styled(MenuItem)`
  border-bottom: 1px solid #cccccc !important;
  min-height: 70px !important;
`;

StyledMenuItem.displayName = 'StyledMenuItem';

const UserInfoSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-width: 316px;
`;

UserInfoSection.displayName = 'UserInfoSection';

const User = styled.div`
  display: flex;
`;

User.displayName = 'User';

const UserData = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-left: 10px;
`;

UserData.displayName = 'UserData';

const UserName = styled.div`
  color: #4c4c4c;
  font-family: 'Avenir Next', sans-serif;
  font-weight: 600;
  font-size: 16px;
  letter-spacing: 0;
`;

UserName.displayName = 'UserName';

const UserEmail = styled.div`
  font-family: 'Avenir Next', sans-serif;
  font-weight: 500;
  font-size: 12px;
  color: #808080;
  letter-spacing: 0;
`;

UserEmail.displayName = 'UserEmail';

const UserRole = styled.div`
  margin-left: 20px;
  padding: 4px 16px;
  border: 1px solid #808080;
  border-radius: 4px;
  font-family: 'Avenir Next', sans-serif;
  font-weight: 600;
  font-size: 13px;
  color: #808080;
  text-align: center;
`;

UserRole.displayName = 'UserRole';

const SignOutMenuItem = styled(MenuItem)`
  height: 50px;
`;

SignOutMenuItem.displayName = 'SignOutMenuItem';

const SignOutImg = styled.img`
  height: 20px;
  margin-right: 20px;
`;

SignOutImg.displayName = 'SignOutImg';

const SignOutText = styled.span`
  font-family: 'Avenir Next', sans-serif;
  font-weight: 500;
  font-size: 14px;
  color: #4c4c4c;
  letter-spacing: 0;
`;

SignOutText.displayName = 'SignOutText';
