import { ReactElement, ReactNode } from 'react';
import styled from '@emotion/styled';

import CloseIcon from '@mui/icons-material/Close';

import useTxnLookupActions from '../../../../TxnLookupContext';
import { InfoPanelHeader, InfoPanelWrapper } from '../InfoPanelLayout';

const DetailsWrapper = ({ label, children }: { label?: string; children?: ReactNode }): ReactElement => {
  const { closeDetailsPanel } = useTxnLookupActions();

  return (
    <InfoPanelWrapper>
      <CloseDetails onClick={closeDetailsPanel}>
        <CloseIcon />
      </CloseDetails>
      <InfoPanelHeader>{label}</InfoPanelHeader>
      <div>{children}</div>
    </InfoPanelWrapper>
  );
};

const CloseDetails = styled('div')`
  position: absolute;
  right: 8px;
  top: 4px;

  &:hover {
    cursor: pointer;
  }
`;

export default DetailsWrapper;
