import { FocusEvent } from 'react';
import { useController } from 'react-hook-form';
import { TextField as MuiTextField } from '@mui/material';
import { styled } from '@mui/material/styles';

interface InputFieldProps {
  autoFocus?: boolean;
  control: any; // TODO Add type
  defaultValue?: string;
  disabled?: boolean;
  error?: boolean;
  helperText?: string;
  fullWidth?: boolean;
  label?: string;
  multiline?: boolean;
  name: string;
  placeholder?: string;
  required?: boolean;
  rows?: number;
  rules?: any; // TODO Add type
  trimSpaces?: boolean;
  type?: string;
  variant?: 'standard' | 'filled' | 'outlined';
}

const InputField = ({
  autoFocus,
  control,
  defaultValue = '',
  disabled,
  error,
  helperText,
  fullWidth,
  label,
  multiline,
  name,
  placeholder = '',
  required,
  rows,
  rules,
  trimSpaces = false,
  type = 'text',
  variant,
}: InputFieldProps) => {
  const {
    field: { onChange, onBlur, value, ref },
  } = useController({
    name,
    control,
    rules,
    defaultValue,
  });

  const updatedOnBlur = (event: FocusEvent<HTMLInputElement>) => {
    if (trimSpaces) {
      // trims spaces on blur using controller's onChange
      const trimmedValue = (event.target.value || '').trim();

      onChange(trimmedValue);
    }

    onBlur(); // sends the input's onBlur event to the form
  };

  return (
    <StyledMuiTextField
      autoFocus={autoFocus}
      disabled={disabled}
      error={error}
      helperText={helperText}
      inputRef={ref}
      inputProps={{ style: { WebkitBoxShadow: '0 0 0 30px white inset' } }} // disables the webkit autofill styling
      fullWidth={fullWidth}
      label={label}
      multiline={multiline}
      name={name}
      onChange={onChange}
      onBlur={updatedOnBlur}
      placeholder={placeholder}
      required={required}
      rows={rows}
      type={type}
      value={value}
      variant={variant}
    />
  );
};

// TODO Move to createMuiTheme wrapper
const StyledMuiTextField = styled(MuiTextField)`
  width: 100%;
  margin: 0 0 8px 0;

  input {
    font-size: 14px;
  }
`;

export default InputField;
