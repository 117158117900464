import { createTheme, ThemeOptions } from '@mui/material/styles';

const themeOptions: ThemeOptions = {
  spacing: [0, 4, 8, 16, 32, 64],
  typography: {
    fontSize: 13,
  },
  components: {
    MuiSelect: {
      defaultProps: {
        size: 'small',
      },
      styleOverrides: {},
    },
    MuiTextField: {
      defaultProps: {
        size: 'small',
      },
      styleOverrides: {
        root: {},
      },
    },
    MuiSwitch: {
      defaultProps: {
        size: 'small',
      },
    },
    MuiAutocomplete: {
      defaultProps: {
        size: 'small',
      },
    },
  },
};

export const theme = createTheme(themeOptions);
