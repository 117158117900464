import { ChangeEvent, useState } from 'react';
import moment from 'moment';
import { get, isArray, isEmpty, isNil, isNumber, map, reduce } from 'lodash';

import Backdrop from '@mui/material/Backdrop';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';

import logger from 'src/services/logger';
import { ActionButtonContainer } from 'src/components/layouts/Layout';
import { ADDITIONAL_FILTERS_TYPE, FilterValuesType, MAIN_FILTERS_TYPE } from 'src/constants/filterConstants';
import { LOAD_DATA_PROPS } from 'src/constants/accountConstants';
import { toastError } from 'src/helpers/toastHelpers';

const DOWNLOAD_LIMIT = 1000;
const DOWNLOAD_STEP = 100;

const getNonEmptyFilters = (filters: ADDITIONAL_FILTERS_TYPE): [string, string][] => {
  const nonEmptyFilters = reduce(
    filters,
    (memo: any[], value: any, key: string): any[] => {
      if ((isArray(value) && isEmpty(value)) || (!value && !isNumber(value))) {
        return memo;
      }

      const adjustedValue = isArray(value) ? value.join(',') : value;

      return [...memo, [key, adjustedValue]];
    },
    []
  );

  return nonEmptyFilters;
};

const getFilterRows = (filters?: ADDITIONAL_FILTERS_TYPE) => {
  if (!filters) {
    return [];
  }

  const csvFilters = getNonEmptyFilters(filters);

  return isEmpty(csvFilters) ? [] : [['Filter', 'Filter Value(s)'], ...csvFilters, ['', '']];
};

const downloadCsv = (rows: any[], fileName: string) => {
  const csvContent = `data:text/csv;charset=utf-8,${map(rows, (row: any[]) => row.join(',')).join('\n')}`;
  const encodedUri = encodeURI(csvContent);
  const link = document.createElement('a');

  link.setAttribute('href', encodedUri);

  link.setAttribute('download', fileName);
  document.body.appendChild(link);

  link.click();
};

const getFileName = (filters: MAIN_FILTERS_TYPE, reportType: string, offset: number) => {
  const id = get(filters, get(filters, 'requestIdType', ''), '');
  const today = moment().format('MM-DD-YYYY');

  return `${id}_${reportType.toLowerCase()}_activities_${today}_${offset}.csv`;
};

const getCsvRows = (paginatedData: any[], headerRowsConfig: Record<string, string>) => {
  const result = reduce(
    paginatedData,
    (memo: any[], paginatedItems) => {
      const normalizedValues = get(paginatedItems, 'nodes', []).map((row: Record<string, any>) => {
        return map(headerRowsConfig, (headerLabel, attr) => get(row, attr, ''));
      });

      return [...memo, ...normalizedValues];
    },
    []
  );

  return result;
};

const getHeaderRow = (headerRowsConfig: Record<string, string> = {}): string[] => map(headerRowsConfig, value => value);

interface DownloadReportActionProps {
  additionalFilters?: ADDITIONAL_FILTERS_TYPE;
  disabled: boolean;
  headerRowsConfig: Record<string, string>;
  isLogicalAccount?: boolean;
  filters?: FilterValuesType | null;
  loadData: ({ additionalFilters, filters, isLogicalAccount, limit, offset }: LOAD_DATA_PROPS) => Promise<any>;
  maxLimit?: number;
  reportType: string;
}

const ReportDownloader = ({
  additionalFilters,
  disabled,
  headerRowsConfig,
  filters,
  isLogicalAccount,
  loadData,
  maxLimit,
  reportType,
}: DownloadReportActionProps) => {
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  return (
    <ActionButtonContainer>
      <Button disabled={disabled} onClick={handleClickOpen} variant="outlined">
        Export to CSV
      </Button>
      <ReportDownloaderContent
        additionalFilters={additionalFilters}
        headerRowsConfig={headerRowsConfig}
        filters={filters}
        isLogicalAccount={isLogicalAccount}
        loadData={loadData}
        maxLimit={maxLimit}
        open={open}
        reportType={reportType}
        setOpen={setOpen}
      />
    </ActionButtonContainer>
  );
};

interface ReportDownloaderContentProps {
  additionalFilters?: ADDITIONAL_FILTERS_TYPE;
  filters: MAIN_FILTERS_TYPE;
  headerRowsConfig: Record<string, string>;
  isLogicalAccount?: boolean;
  loadData: ({ additionalFilters, filters, limit, offset }: LOAD_DATA_PROPS) => Promise<any>;
  maxLimit?: number;
  open: boolean;
  reportType: string;
  setOpen: (open: boolean) => void;
}

const ReportDownloaderContent = ({
  additionalFilters,
  filters,
  headerRowsConfig = {},
  isLogicalAccount,
  loadData,
  open,
  setOpen,
  maxLimit,
  reportType,
}: ReportDownloaderContentProps) => {
  const [hasErrors, setHasErrors] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [offset, setOffset] = useState(0);

  const downloadPaginated = async () => {
    const paginatedPromises = [];
    const maximumListSize =
      isNil(maxLimit) || !isNumber(maxLimit)
        ? offset + 1000 - DOWNLOAD_STEP
        : Math.min(offset + DOWNLOAD_LIMIT - DOWNLOAD_STEP, maxLimit);

    for (let i = offset; i <= maximumListSize; i = i + DOWNLOAD_STEP) {
      const request = loadData({ additionalFilters, filters, isLogicalAccount, limit: DOWNLOAD_STEP, offset: i });

      paginatedPromises.push(request);
    }

    return await Promise.all(paginatedPromises);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = async () => {
    try {
      setIsLoading(true);

      const paginatedList = await downloadPaginated();
      const filterRows = getFilterRows(additionalFilters);
      const headerRow = getHeaderRow(headerRowsConfig);
      const csvBodyRows = getCsvRows(paginatedList, headerRowsConfig);
      const csvContent = [...filterRows, headerRow, ...csvBodyRows];

      const fileName = getFileName(filters, reportType, offset);

      downloadCsv(csvContent, fileName);

      setOpen(false);
    } catch (e) {
      const errorMessage = 'Error while preparing data for CSV file';

      logger.error(errorMessage);
      toastError(errorMessage, {
        toastId: 'csvDownloadError',
      });
    } finally {
      setIsLoading(false);
    }
  };

  const onOffsetChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = parseInt(event.target.value, 10) || 0;

    setOffset(value);

    const valueIsInvalid = (maxLimit && value > maxLimit) || value < 0;

    setHasErrors(!!valueIsInvalid);
  };

  const helperText = hasErrors ? `The offset value cannot be less than 0 or greater than ${maxLimit}` : ' ';

  return (
    <Dialog open={open} onClose={handleClose}>
      <Backdrop sx={{ color: '#ccc', zIndex: 100 }} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <DialogTitle>Download {reportType} List</DialogTitle>
      <DialogContent>
        <DialogContentText>
          The list will be downloaded as .CSV file.
          <br />
          The maximum size of the download list is {DOWNLOAD_LIMIT} records.
        </DialogContentText>
        <TextField
          autoFocus
          disabled={isLoading}
          error={hasErrors}
          helperText={helperText}
          id="outlined-basic"
          label="Start offset"
          margin="normal"
          onChange={onOffsetChange}
          type="number"
          value={offset}
          variant="outlined"
        />
        <DialogContentText>
          Specify the results by applying filters or setting the starting offset using the input above.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button disabled={isLoading} onClick={handleClose}>
          Cancel
        </Button>
        <Button disabled={hasErrors || isLoading} onClick={handleSubmit}>
          Download CSV
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ReportDownloader;
