import { ReactElement, useState } from 'react';
import styled from '@emotion/styled';

import CurrentPriceProfile from './components/CurrentPriceProfile';
import PriceProfiles from './components/PriceProfiles';
import useUserData from 'src/context/UserContext';
import { Account } from 'src/constants/accountConstants';

export default function PriceProfileManagement({ data }: { data: Account }): ReactElement {
  const [currentPriceProfile, setCurrentPriceProfile] = useState('');
  const { userAbility } = useUserData();

  const userCanManagePricingProfiles = userAbility.can('manage', 'PRICING_PROFILES');

  return (
    <PriceProfileManagementWrapper>
      <CurrentPriceProfile accountData={data} onPriceProfileInfoLoaded={setCurrentPriceProfile} />
      {userCanManagePricingProfiles ? (
        <PriceProfiles currentPriceProfile={currentPriceProfile} accountData={data} />
      ) : null}
    </PriceProfileManagementWrapper>
  );
}

const PriceProfileManagementWrapper = styled('div')`
  display: flex;
  padding: 8px 0;
  width: 100%;
`;
