import { createGlobalStyle } from 'styled-components';

import 'react-toastify/dist/ReactToastify.css';

export default createGlobalStyle`
  body, html {
    height: 100%;
  }

  *, ::after, ::before {
    box-sizing: border-box;
  }

  body {
    background: #F4F6F8;
    font-family: 'Avenir Next', sans-serif;
    margin: 0;
    padding: 0;
  }

  // fonts
  @font-face {
    font-family: 'Avenir Next';
    src: font-url('/assets/fonts/avenir-next-regular.woff') format('woff'), 
         font-url('/assets/fonts/avenir-next-regular.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'Avenir Next';
    src: font-url('/assets/fonts/avenir-next-thin.woff') format('woff');
    font-weight: 100;
    font-style: normal;
  }

  @font-face {
    font-family: 'Avenir Next';
    src: font-url('/assets/fonts/avenir-next-ultralight.woff') format('woff'), 
         font-url('/assets/fonts/avenir-next-ultralight.woff2') format('woff2');
    font-weight: 300;
    font-style: normal;
  }

  @font-face {
    font-family: 'Avenir Next';
    src: font-url('/assets/fonts/avenir-next-medium.woff') format('woff'), 
         font-url('/assets/fonts/avenir-next-medium.woff2') format('woff2');
    font-weight: 500;
    font-style: normal;
  }

  @font-face {
    font-family: 'Avenir Next';
    src: font-url('/assets/fonts/avenir-next-bold.woff') format('woff'),
         font-url('/assets/fonts/avenir-next-bold.woff2') format('woff2');    
    font-weight: bold;
    font-style: normal;
  }

  @font-face {
    font-family: 'Avenir Next Demi';
    src: font-url('/assets/fonts/avenir-next-demibold.woff') format('woff'),
         font-url('/assets/fonts/avenir-next-demibold.woff2') format('woff2');
    font-style: normal;
  }

  // Toasts 
  // TODO Move into a separate style file
  :root {
    --toastify-color-light: #fff;
    --toastify-color-dark: #121212;
    --toastify-color-info: #3498db;
    --toastify-color-success: #07bc0c;
    --toastify-color-warning: #f1c40f;
    --toastify-color-error: #e74c3c;
    --toastify-color-transparent: hsla(0, 0%, 100%, .7);
    --toastify-icon-color-info: var(--toastify-color-info);
    --toastify-icon-color-success: var(--toastify-color-success);
    --toastify-icon-color-warning: var(--toastify-color-warning);
    --toastify-icon-color-error: var(--toastify-color-error);
    --toastify-toast-width: 320px;
    --toastify-toast-background: #fff;
    --toastify-toast-min-height: 64px;
    --toastify-toast-max-height: 800px;
    --toastify-font-family: sans-serif;
    --toastify-z-index: 9999;
    --toastify-text-color-light: #757575;
    --toastify-text-color-dark: #fff;
    --toastify-text-color-info: #fff;
    --toastify-text-color-success: #fff;
    --toastify-text-color-warning: #fff;
    --toastify-text-color-error: #fff;
    --toastify-spinner-color: #616161;
    --toastify-spinner-color-empty-area: #e0e0e0;
    --toastify-color-progress-light: linear-gradient(90deg, #4cd964, #5ac8fa, #007aff, #34aadc, #5856d6, #ff2d55);
    --toastify-color-progress-dark: #bb86fc;
    --toastify-color-progress-info: var(--toastify-color-info);
    --toastify-color-progress-success: var(--toastify-color-success);
    --toastify-color-progress-warning: var(--toastify-color-warning);
    --toastify-color-progress-error: var(--toastify-color-error)
  }

  @keyframes Toastify__trackProgress {
    0% {
      transform: scaleX(1)
    }
    to {
      transform: scaleX(0)
    }
  }

  .Toastify__progress-bar {
    animation: Toastify__trackProgress linear 1 forwards
  }
`;
