import { gql } from '@apollo/client';

export const REPORT_CONFIGS_QUERY = gql`
  query reportConfigs {
    reportConfigs {
      id
      params
      outputFormats
      reportFields
      fetchFromSource
      paginationLimit
      isRealtimeable
      rootNode
      source
      status
    }
  }
`;
