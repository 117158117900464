import { forEach, isEmpty } from 'lodash';
import { useCallback, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

const useUrlParams = () => {
  const { search } = useLocation();
  const history = useHistory();

  // TODO Replace with URLSearchParams type
  //  and resolve an issue in Account.tsx related to the wrong type of .get() method
  const [urlParams] = useState<any>(() => new URLSearchParams(search));

  const updateUrlParams = useCallback(
    (entries: Record<string, any>) => {
      if (isEmpty(entries)) {
        history.push({ search: '' });
      } else {
        forEach(entries, (value, key) => {
          value ? urlParams.set(key, value) : urlParams.delete(key); // empty value deletes its entry from params
        });

        history.push({ search: urlParams.toString() });
      }
    },
    [history, urlParams]
  );

  return [urlParams, updateUrlParams];
};

export default useUrlParams;
