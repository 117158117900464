import CssBaseline from '@mui/material/CssBaseline';
import { ApmRoute } from '@elastic/apm-rum-react';
import { BrowserRouter as Router, Redirect, Switch } from 'react-router-dom';
import { Fragment, ReactElement } from 'react';
import { Normalize } from 'styled-normalize';
import { ThemeProvider } from '@mui/material/styles';
import { ToastContainer } from 'react-toastify';

import Account from './containers/account/Account';
import AggregatedReports from './containers/aggregatedReports/AggregatedReports';
import AppHeader from './components/appHeader/AppHeader';
import ErrorBoundary from './components/layouts/ErrorBoundary';
import GlobalStyles from './GlobalStyles';
import MainMenu from './containers/mainMenu/MainMenu';
import NotFoundContainer from './components/layouts/NotFound';
import Reporting from './containers/reporting/Reporting';
import SingleAccountPayout from './containers/account/components/singlePayout/SingleAccountPayout';
import SingleLedger from './containers/account/components/singleLedger/SingleLedger';
import TxnLookup from './containers/txnLookup/TxnLookup';
import UserInfoContainer from './containers/userInfo/UserInfo';
import useUserData, { UserProvider } from './context/UserContext';
import withNetworkHandler from 'src/hoc/withNetworkHandler';

import { GlobalProgressBar } from './components/progress/GlobalProgressBar';
import { GlobalProgressBarProvider } from './context/GlobalProgressBarContext';
import { ContentContainer, LayoutContainer } from './components/layouts/Layout';
import { theme } from './theme/theme';

const App = (): ReactElement => (
  <Fragment>
    <Normalize />
    <GlobalStyles />

    <ErrorBoundary>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <UserProvider>
          <GlobalProgressBarProvider>
            <GlobalProgressBar />
            <LayoutContainer>
              <ToastContainer />
              <AppContent />
            </LayoutContainer>
          </GlobalProgressBarProvider>
        </UserProvider>
      </ThemeProvider>
    </ErrorBoundary>
  </Fragment>
);

const AppContent = (): ReactElement => {
  const { user } = useUserData();

  const isUserAuthenticated = user?.isAuthenticated;

  return (
    <Router>
      <AppHeader>
        {isUserAuthenticated ? (
          <>
            <MainMenu />
            <UserInfoContainer />
          </>
        ) : null}
      </AppHeader>
      {isUserAuthenticated ? (
        <ContentContainer>
          <Switch>
            <Redirect from="/" to="/account" exact />
            <ApmRoute path="/account/:accountId/type/:reqIdType/payout/:payoutId" component={SingleAccountPayout} />
            <ApmRoute path="/account/:accountId/type/:reqIdType/ledger/:ledgerId" component={SingleLedger} />
            <ApmRoute path="/account" component={Account} />
            <ApmRoute path="/aggregated-reports" component={AggregatedReports} />
            <ApmRoute path="/txn-lookup/:txnId" component={TxnLookup} />
            <ApmRoute path="/txn-lookup" component={TxnLookup} />
            <ApmRoute path="/reporting-service" component={Reporting} />
            <ApmRoute path="**" component={NotFoundContainer} />
          </Switch>
        </ContentContainer>
      ) : null}
    </Router>
  );
};

export default withNetworkHandler(App);
