import { ReactElement, useCallback, useState } from 'react';
import { get } from 'lodash';
import { Paper, styled } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';

import CommonViewer from 'src/components/viewer/CommonViewer';
import InfoList from 'src/components/infoList/InfoList';
import TabSwitcher from 'src/components/tabs/TabSwitcher';
import { FinInstrumentAttrDefs, PayoutAttrDefs, TransferAttrDefs } from './constants/singlePayoutConstants';
import { Header } from 'src/components/layouts/Layout';
import { SINGLE_ACCOUNT_PAYOUT_QUERY } from 'src/services/apollo/queries/accountQueries';

interface SingleAccountProps {
  goBackUrl?: string;
}

const SingleAccountPayout = ({ goBackUrl }: SingleAccountProps): ReactElement => {
  const urlParams = useParams();
  const accountId = decodeURIComponent(get(urlParams, 'accountId', ''));
  const requestIdType = decodeURIComponent(get(urlParams, 'reqIdType', ''));
  const payoutId = decodeURIComponent(get(urlParams, 'payoutId', ''));

  const [currentTabIndex, setCurrentTabIndex] = useState<number>(0);

  const requestVariables = {
    accountId,
    requestIdType,
    payoutId,
  };

  const { loading: isLoading, data: payoutInfo } = useQuery(SINGLE_ACCOUNT_PAYOUT_QUERY, {
    variables: requestVariables,
    errorPolicy: 'all', // show partial data even if nested resolvers get errors
  });

  const tabsConfig = [
    {
      label: 'Transfer Info',
      tabContent: (
        <TabContentWrapper>
          <InfoList
            attrDefinitions={TransferAttrDefs}
            context={requestVariables} // variables to refetch the payout info for embedded mutations
            data={payoutInfo?.singleAccountPayout?.singleTransfer}
          />
        </TabContentWrapper>
      ),
    },
    {
      label: 'Financial Instrument',
      tabContent: (
        <TabContentWrapper>
          <InfoList
            attrDefinitions={FinInstrumentAttrDefs}
            data={payoutInfo?.singleAccountPayout?.singleTransfer?.financialInstrument}
          />
        </TabContentWrapper>
      ),
    },
  ];

  const onTabChange = useCallback((index: number) => {
    setCurrentTabIndex(index);
  }, []);

  const content = (
    <>
      <InfoContent>
        <Header>Payout Info</Header>
        <InfoList attrDefinitions={PayoutAttrDefs} data={payoutInfo?.singleAccountPayout} />
      </InfoContent>
      <TabSwitcher currentTabIndex={currentTabIndex} onTabChange={onTabChange} tabsConfig={tabsConfig} />
    </>
  );

  return <CommonViewer goBackUrl={goBackUrl} isLoading={isLoading} content={content} />;
};

const ContentWrapper = styled('div')`
  align-items: flex-start;
  display: flex;
  padding: 0 24px;
  width: 100%;
`;

ContentWrapper.displayName = 'ContentWrapper';

const InfoContent = styled(Paper)`
  flex-basis: 70%;
  margin-top: 48px;
  margin-right: 48px;
  min-width: 500px;
  padding: 0 24px 24px;
`;

InfoContent.displayName = 'InfoContent';

const TabContentWrapper = styled('div')`
  display: flex;
  justify-content: center;
  padding-right: 24px;
  padding-left: 24px;
  width: 100%;
`;

TabContentWrapper.displayName = 'TabContentWrapper';

export default SingleAccountPayout;
