import { ReactNode, SyntheticEvent } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';

interface TabPanelProps {
  children?: ReactNode;
  index: number;
  value: number;
}

type TabItemConfig = {
  disabled?: boolean;
  label: string;
  tabContent: ReactNode;
  type?: string;
};

interface TabsProps {
  currentTabIndex: number;
  onTabChange?: (index: number) => void;
  tabsConfig: TabItemConfig[];
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`tabpanel-${index}`} aria-labelledby={`tab-${index}`} {...other}>
      {value === index && <Box sx={{ p: 3, padding: '24px 0 0 0' }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    'id': `tab-${index}`,
    'aria-controls': `tabpanel-${index}`,
  };
}

const TabsSwitcher = (props: TabsProps) => {
  const { currentTabIndex = 0, tabsConfig = [], onTabChange } = props;

  const handleChange = (event: SyntheticEvent, newValue: number) => {
    onTabChange?.(newValue);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={currentTabIndex} onChange={handleChange} aria-label="tabs" variant="fullWidth">
          {tabsConfig.map((tabConfig, idx) => (
            <Tab disabled={tabConfig.disabled} label={tabConfig.label} key={tabConfig.label} {...a11yProps(idx)} />
          ))}
        </Tabs>
      </Box>
      {tabsConfig.map((tabConfig, idx) => (
        <TabPanel index={idx} key={tabConfig.label} value={currentTabIndex}>
          {tabConfig.tabContent}
        </TabPanel>
      ))}
    </Box>
  );
};

export default TabsSwitcher;
