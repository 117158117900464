import { createContext, ReactElement, ReactNode, useContext, useMemo, useState } from 'react';

interface TxnLookupContextType {
  closeDetailsPanel: () => void;
  detailsPanelState: DetailsPanelType;
  onLedgerClick: (id: string) => void;
  onPayoutClick: (id: string) => void;
  onDepositClick: (id: string) => void;
}

export enum DETAILS_PANEL_STATE {
  'hidden',
  'ledger',
  'payout',
  'deposit',
}

type DetailsPanelType = {
  state: DETAILS_PANEL_STATE;
  itemId: string | null;
};

const TxnLookupContext = createContext<TxnLookupContextType>({} as TxnLookupContextType);

export function TxnLookupProvider({ children }: { children: ReactNode }): ReactElement {
  const [detailsPanelState, setDetailsPanelState] = useState<DetailsPanelType>({
    state: DETAILS_PANEL_STATE.hidden,
    itemId: null,
  });

  const memoedValue = useMemo(
    () => ({
      closeDetailsPanel: () => setDetailsPanelState({ state: DETAILS_PANEL_STATE.hidden, itemId: null }),
      detailsPanelState,
      onLedgerClick: (ledgerId: string) => {
        setDetailsPanelState({ state: DETAILS_PANEL_STATE.ledger, itemId: ledgerId });
      },
      onDepositClick: (depositId: string) => {
        setDetailsPanelState({ state: DETAILS_PANEL_STATE.deposit, itemId: depositId });
      },
      onPayoutClick: (payoutId: string) => {
        setDetailsPanelState({ state: DETAILS_PANEL_STATE.payout, itemId: payoutId });
      },
    }),
    [detailsPanelState]
  );

  return <TxnLookupContext.Provider value={memoedValue}>{children}</TxnLookupContext.Provider>;
}

export default function useTxnLookupActions(): TxnLookupContextType {
  return useContext(TxnLookupContext);
}
