import { useApolloClient } from '@apollo/client';
import { useCallback, useState } from 'react';

import { AggregatedReportType } from '../constants/reportConstants';
import { convertFileToBase64 } from 'src/helpers/fileHelpers';
import { REPORT_UPLOAD } from 'src/services/apollo/mutations/aggregatedReportMutation';

export const useUploader = (aggregatedReportType: AggregatedReportType) => {
  const apolloClient = useApolloClient();

  const [loading, setLoading] = useState(false);

  const uploadFile = useCallback(
    async (file: File) => {
      setLoading(true);
      try {
        const result = await apolloClient.mutate({
          mutation: REPORT_UPLOAD,
          variables: {
            uploadData: {
              originalFilename: file.name,
              aggregatedReportType,
              content: await convertFileToBase64(file),
            },
          },
        });

        return result;
      } finally {
        setLoading(false);
      }
    },
    [apolloClient, aggregatedReportType]
  );

  return {
    uploadFile,
    loading,
  };
};
