import styled from '@emotion/styled';
import { isEmpty } from 'lodash';
import { ReactElement } from 'react';

import BaseTransaction from './components/transactions/BaseTransaction';
import DepositDetails from './components/nodeDetails/DepositDetails';
import LedgerDetails from './components/nodeDetails/LedgerDetails';
import PayoutDetails from './components/nodeDetails/PayoutDetails';
import SingleTransactionInfo from './components/transactions/SingleTransactionInfo';
import useTxnLookupActions, { DETAILS_PANEL_STATE } from '../../TxnLookupContext';
import { InfoPanelHeader, InfoPanelWrapper } from './components/InfoPanelLayout';
import { FullTxnData, TxnInfo } from '../../constants/txnConstants';

const DetailsComponentMapper = {
  [DETAILS_PANEL_STATE.ledger]: LedgerDetails,
  [DETAILS_PANEL_STATE.payout]: PayoutDetails,
  [DETAILS_PANEL_STATE.deposit]: DepositDetails,
};

const InfoPanel = ({
  fullTxnData,
  baseTxnId,
}: {
  fullTxnData: FullTxnData | null;
  baseTxnId: string;
}): ReactElement | null => {
  const { detailsPanelState } = useTxnLookupActions();

  const { transactionData: txnHierarchyData, accountData } = fullTxnData || {};

  if (!txnHierarchyData?.baseTxn?.id) {
    return null;
  }

  const { baseTxn, parentTxn, childTxns } = txnHierarchyData;

  if (detailsPanelState.state !== DETAILS_PANEL_STATE.hidden && DetailsComponentMapper[detailsPanelState.state]) {
    const DetailsComponent = DetailsComponentMapper[detailsPanelState.state];

    return <DetailsComponent accountId={accountData?.id} txnData={baseTxn} itemId={detailsPanelState.itemId} />;
  }

  const isActive = baseTxnId === baseTxn.id;

  return (
    <InfoPanelWrapper>
      <InfoPanelHeader>Transaction Detail & History</InfoPanelHeader>
      <FirstLevelTxn txnData={parentTxn} />
      <BaseTransaction
        baseTxnId={baseTxnId}
        childTransactions={childTxns}
        hasParent={!isEmpty(parentTxn)}
        isActive={isActive}
        txnData={baseTxn}
      />
    </InfoPanelWrapper>
  );
};

const FirstLevelTxn = ({ txnData }: { txnData?: TxnInfo }) => {
  const content = txnData ? <SingleTransactionInfo data={txnData} /> : null;

  return <FirstLevelTxnWrapper>{content}</FirstLevelTxnWrapper>;
};

const FirstLevelTxnWrapper = styled('div')`
  margin-bottom: 12px;
  padding-right: 32px;
`;

export default InfoPanel;
