import { ReactElement } from 'react';
import { styled } from '@mui/material';
import { useQuery } from '@apollo/client';

import InfoList from 'src/components/infoList/InfoList';
import Spinner, { SpinnerContainer } from 'src/components/spinner/Spinner';
import { Header } from 'src/components/layouts/Layout';
import { Link } from 'react-router-dom';
import { PayoutAttrDefs } from '../../singlePayout/constants/singlePayoutConstants';
import { SINGLE_ACCOUNT_PAYOUT_QUERY } from 'src/services/apollo/queries/accountQueries';

interface LedgerPayoutInfoProps {
  accountId: string;
  requestIdType: string;
  payoutId: string;
}

const LedgerPayoutInfo = ({ accountId, requestIdType, payoutId }: LedgerPayoutInfoProps): ReactElement => {
  const { loading: isLoading, data } = useQuery(SINGLE_ACCOUNT_PAYOUT_QUERY, {
    variables: { accountId, requestIdType, payoutId },
    errorPolicy: 'all', // show partial data even if nested resolvers get errors
  });

  const payoutLink = `/account/${encodeURIComponent(accountId)}/type/${encodeURIComponent(
    requestIdType
  )}/payout/${encodeURIComponent(payoutId)}`;

  return (
    <PayoutInfoContainer>
      {isLoading ? (
        <SpinnerContainer>
          <Spinner />
        </SpinnerContainer>
      ) : (
        <>
          <Header>Payout Info</Header>
          <InfoList attrDefinitions={PayoutAttrDefs} data={data?.singleAccountPayout} />
          <StyledLink to={payoutLink}>See full payout information</StyledLink>
        </>
      )}
    </PayoutInfoContainer>
  );
};

const StyledLink = styled(Link)`
  color: #3e6fa3;
  display: flex;
  font-size: 15px;
  padding-top: 24px;
  text-decoration: none;
  text-transform: uppercase;

  &:hover {
    color: #7ca4b3;
  }
`;

StyledLink.displayName = 'StyledLink';

const PayoutInfoContainer = styled('div')`
  margin-right: 48px;
  min-width: 500px;
  padding: 0 24px 24px;
  width: 50%;
`;

PayoutInfoContainer.displayName = 'PayoutInfoContainer';

export default LedgerPayoutInfo;
