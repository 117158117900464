import { toast } from 'react-toastify';

export const toastSuccess = (
  message: string,
  { toastId = 'toastSuccess', autoClose = 2000 }: { toastId?: string; autoClose?: number }
) => {
  toast.success(message, {
    toastId,
    autoClose,
    closeButton: false,
    hideProgressBar: true,
  });
};

export const toastInfo = (message: string, { toastId = 'toastInfo' }: { toastId?: string }) => {
  toast.info(message, {
    toastId,
    autoClose: false,
    closeButton: false,
    closeOnClick: false,
    draggable: false,
    hideProgressBar: true,
    pauseOnHover: false,
    progress: undefined,
  });
};

export const toastError = (
  message: string,
  { toastId = 'toastError', autoClose = false }: { toastId?: string; autoClose?: number | false }
) => {
  toast.error(message, {
    toastId,
    autoClose,
    hideProgressBar: true,
  });
};
