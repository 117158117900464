import { get } from 'lodash';
import { useApolloClient } from '@apollo/client';
import { useCallback, useState } from 'react';

import logger from 'src/services/logger';
import { ACCOUNT_PAYOUTS_QUERY } from 'src/services/apollo/queries/accountQueries';
import { REQUEST_ID_TYPE_KEY } from 'src/constants/filterConstants';
import { DEFAULT_ROWS_PER_PAGE } from 'src/constants/tableConstants';
import { LOAD_DATA_PROPS } from 'src/constants/accountConstants';
import { useGlobalProgressBar } from 'src/context/GlobalProgressBarContext';

export default function usePayoutsData() {
  const [data, setData] = useState(null);
  const { isLoading, setIsLoading } = useGlobalProgressBar();
  const { loadData: loadPayoutsData } = usePayoutsDataLoader();

  const loadData = useCallback(
    async ({ filters, limit = DEFAULT_ROWS_PER_PAGE, offset }: LOAD_DATA_PROPS) => {
      try {
        setIsLoading(true);

        const data = await loadPayoutsData({ filters, limit, offset });

        setData(data);
      } catch (err: any) {
        logger.warn(err?.message);
      } finally {
        setIsLoading(false);
      }
    },
    []
  );

  return { loadData, data, isLoading };
}

export function usePayoutsDataLoader() {
  const apolloClient = useApolloClient();

  const loadData = useCallback(
    async ({ filters, limit = DEFAULT_ROWS_PER_PAGE, offset }: LOAD_DATA_PROPS) => {
      if (!filters) {
        return;
      }

      const requestIdType = get(filters, REQUEST_ID_TYPE_KEY, '');
      const id = get(filters, requestIdType, '');

      try {
        const { data } = await apolloClient.query({
          query: ACCOUNT_PAYOUTS_QUERY,
          variables: {
            id,
            requestIdType,
            offset,
            limit,
          },
        });

        return data?.accountPayouts;
      } catch (err: any) {
        logger.warn(err?.message);
      }
    },
    []
  );

  return { loadData };
}
