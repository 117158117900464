import styled from '@emotion/styled';
import { ReactElement } from 'react';

import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import TextField from '@mui/material/TextField';

import useTxnLookupActions from '../../../TxnLookupContext';

interface SearchProps {
  loading: boolean;
  onSubmit: (txnId: string) => void;
  setTxnSearchId: (txnId: string) => void;
  txnSearchId: string;
}

const Search = ({ loading, onSubmit, setTxnSearchId, txnSearchId }: SearchProps): ReactElement => {
  const { closeDetailsPanel } = useTxnLookupActions();

  const onSubmitHandler = () => {
    closeDetailsPanel();
    onSubmit(txnSearchId);
  };

  const handleKeyDown = (event: any) => {
    if (event.key === 'Enter') {
      onSubmitHandler();
    }
  };

  return (
    <SearchWrapper>
      <SearchInput
        autoFocus
        disabled={loading}
        fullWidth
        label="Search by Full Transaction ID"
        margin="normal"
        onChange={e => {
          const value = (e.target.value || '').trimStart().trimEnd();

          setTxnSearchId(value);
        }}
        onKeyDown={handleKeyDown}
        size="small"
        sx={{ paddingRight: 0 }}
        type="text"
        value={txnSearchId}
        variant="outlined"
        InputProps={{
          endAdornment: txnSearchId.length > 0 && (
            <ResetButton disabled={loading} onClick={() => setTxnSearchId('')}>
              <CloseIcon />
            </ResetButton>
          ),
        }}
      />
      <SearchButton
        color="primary"
        disabled={txnSearchId.length === 0 || loading}
        disableElevation
        onClick={onSubmitHandler}
        type="submit"
        variant="contained"
      >
        <SearchIcon />
      </SearchButton>
    </SearchWrapper>
  );
};

const SearchWrapper = styled('div')`
  align-items: center;
  display: flex;
`;

const SearchInput = styled(TextField)`
  .MuiOutlinedInput-root {
    padding-right: 0;
  }
`;

const SearchButton = styled(Button)`
  min-width: 32px;
  margin-top: 16px;
  margin-bottom: 8px;
  min-height: 38px;
`;

const ResetButton = styled(IconButton)``;

export default Search;
