import styled from 'styled-components';

import InfoList from 'src/components/infoList/InfoList';
import { FilledContainer } from 'src/components/layouts/Layout';
import { stringifyDate } from 'src/helpers/dateTimeHelpers';

const OrganizationInfoAttrDefs = [
  {
    id: 'accountId',
    label: 'Account ID',
  },
  {
    id: 'ownerType',
    label: 'Owner Type',
  },
  {
    id: 'ownerId',
    label: 'Owner ID',
  },
  {
    id: 'createdAt',
    label: 'Created at',
    customRender: stringifyDate,
  },
  {
    id: 'updatedAt',
    label: 'Updated at',
    customRender: stringifyDate,
  },
];

export default function OrganizationInfo({ data = [] }: any) {
  if (!data) {
    return null;
  }

  return (
    <OrganizationsContent>
      {data.map((org: any, idx: number) => (
        <OrgContainer key={idx}>
          <InfoList attrDefinitions={OrganizationInfoAttrDefs} data={org} />
        </OrgContainer>
      ))}
    </OrganizationsContent>
  );
}

const OrgContainer = styled(FilledContainer)`
  margin-bottom: 12px;
`;

OrgContainer.displayName = 'OrgContainer';

const SingleOrgInfo = styled.div``;

SingleOrgInfo.displayName = 'SingleOrgInfo';

const OrganizationsContent = styled.div`
  > ${SingleOrgInfo}:not(:last-child) {
    border-bottom: 1px solid #ccc;
    margin-bottom: 8px;
  }
`;

OrganizationsContent.displayName = 'OrganizationsContent';
