import moment from 'moment';
import { get, isArray, isEmpty, reduce } from 'lodash';
import { useApolloClient } from '@apollo/client';
import { useCallback, useState } from 'react';

import logger from 'src/services/logger';
import { ACCOUNT_LEDGERS_QUERY } from 'src/services/apollo/queries/accountQueries';
import { ADDITIONAL_FILTERS_TYPE, MAIN_FILTERS_TYPE, REQUEST_ID_TYPE_KEY } from 'src/constants/filterConstants';
import { API_FULL_TIME_FORMAT } from 'src/constants/dateTimeConstants';
import { DEFAULT_ROWS_PER_PAGE } from 'src/constants/tableConstants';
import { useGlobalProgressBar } from 'src/context/GlobalProgressBarContext';

const formatFilterValue = (value: any) => {
  if (isArray(value)) {
    return value.join(',');
  }

  if (moment(value).isValid()) {
    return moment(value).format(API_FULL_TIME_FORMAT);
  }

  return value;
};

export const getAdditionalFilterParams = (additionalFilters: ADDITIONAL_FILTERS_TYPE) => {
  if (isEmpty(additionalFilters)) {
    return '';
  }

  const params = reduce(
    additionalFilters,
    (memo: string[], value: any, key: string) => {
      if (!value || (isArray(value) && isEmpty(value))) {
        return memo;
      }

      const result = `${key}=${formatFilterValue(value)}`;

      return [...memo, result];
    },
    []
  );

  return params.join('&');
};

interface LoadDataProps {
  filters: MAIN_FILTERS_TYPE;
  additionalFilters?: ADDITIONAL_FILTERS_TYPE;
  isLogicalAccount?: boolean;
  limit?: number;
  offset: number;
}

export default function useLedgersData() {
  const [data, setData] = useState(null);
  const { isLoading, setIsLoading } = useGlobalProgressBar();
  const { loadData: ledgersDataLoader } = useLedgersDataLoader();

  const loadData = useCallback(
    async ({
      additionalFilters = {},
      filters,
      isLogicalAccount,
      limit = DEFAULT_ROWS_PER_PAGE,
      offset,
    }: LoadDataProps) => {
      try {
        setIsLoading(true);

        const data = await ledgersDataLoader({ additionalFilters, filters, isLogicalAccount, limit, offset });

        setData(data);
      } catch (err: any) {
        logger.warn(err?.message);
      } finally {
        setIsLoading(false);
      }
    },
    []
  );

  return { loadData, data, isLoading };
}

export function useLedgersDataLoader() {
  const apolloClient = useApolloClient();

  const loadData = useCallback(
    async ({
      additionalFilters = {},
      filters,
      isLogicalAccount,
      limit = DEFAULT_ROWS_PER_PAGE,
      offset,
    }: LoadDataProps) => {
      if (!filters) {
        return;
      }

      const requestIdType = get(filters, REQUEST_ID_TYPE_KEY, '');
      const id = get(filters, requestIdType, '');

      const additionalFilterParams = getAdditionalFilterParams(additionalFilters);

      try {
        const { data } = await apolloClient.query({
          query: ACCOUNT_LEDGERS_QUERY,
          variables: {
            id,
            requestIdType,
            isLogicalAccount,
            filterString: additionalFilterParams,
            offset,
            limit,
          },
        });

        return data?.accountLedgers;
      } catch (err: any) {
        logger.warn(err?.message);
      }
    },
    []
  );

  return { loadData };
}
