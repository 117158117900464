import { ReactElement, useEffect, useState } from 'react';
import styled from 'styled-components';
import { find, get, isEmpty, map, reduce, size } from 'lodash';
import { useHistory } from 'react-router-dom';
import { useMutation } from '@apollo/client';

import Backdrop from '@mui/material/Backdrop';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import Paper from '@mui/material/Paper';
import {
  DialogActions,
  IconButton,
  Table as MuiTable,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';

import AccountSelector from './AccountSelector';
import Dropdown from 'src/components/common/Dropdown/Dropdown';
import useBankAccounts from '../hooks/useBankAccounts';
import { ActionButtonContainer } from 'src/components/layouts/Layout';
import { clearPayoutsCache } from 'src/services/apollo/helpers/cacheHelpers';
import { CREATE_PAYOUT_FROM_LEDGERS_MUTATION } from 'src/services/apollo/mutations/ledgerMutations';
import { getBankAccountDropdownData } from './helpers/createPayoutFromLedgersHelpers';
import { getOptions } from 'src/helpers/commonHelpers';
import { LedgerType } from 'src/containers/account/constants/ledgerConstants';
import { SUPPORTED_CURRENCIES } from 'src/constants/commonConstants';
import { toastError } from 'src/helpers/toastHelpers';

const supportedCurrencyOptions = getOptions(SUPPORTED_CURRENCIES);

interface LedgersSelectionProps {
  selectedLedgers: LedgerType[];
}

export const SelectedLedgersInfo = ({ selectedLedgers }: LedgersSelectionProps): ReactElement | null => {
  const numberOfSelectedLedgers = size(selectedLedgers);
  const totalSelectedLedgersAmount = reduce(
    selectedLedgers,
    (sum, { amount = 0 }) => {
      return sum + amount;
    },
    0
  );

  return isEmpty(selectedLedgers) ? null : (
    <span>
      selected <StrongText>{numberOfSelectedLedgers}</StrongText> ledger{numberOfSelectedLedgers === 1 ? '' : 's'} with
      the total amount <StrongText>{totalSelectedLedgersAmount}</StrongText>
    </span>
  );
};

interface CreatePayoutFromLedgersProps {
  accountId: string;
  availableBalance?: number;
  id: string;
  onSubmit: () => void;
  removeLedger: (ledger: LedgerType) => void;
  requestIdType: string;
  selectedLedgers: LedgerType[];
}

const CreatePayoutFromLedgers = ({
  accountId,
  availableBalance = 0,
  id,
  onSubmit,
  removeLedger,
  requestIdType,
  selectedLedgers,
}: CreatePayoutFromLedgersProps): ReactElement => {
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [createPayoutHandler, { error: actionError }] = useMutation(CREATE_PAYOUT_FROM_LEDGERS_MUTATION, {
    update: clearPayoutsCache,
  });
  const { bankAccounts, isLoading: areBankAccountsLoading } = useBankAccounts(accountId);
  const [selectedBankAccount, setBankAccount] = useState('');
  const [currency, setCurrency] = useState('USD');

  useEffect(() => {
    const defaultBankAccount = find(bankAccounts, { isDefault: true });

    if (defaultBankAccount) {
      setBankAccount(defaultBankAccount?.id);
    }
  }, [bankAccounts]);

  const handleClose = () => {
    setOpen(false);
  };

  const validateSelectedLedgers = () => {
    const totalSelectedLedgersAmount = reduce(
      selectedLedgers,
      (sum, { amount = 0 }) => {
        return sum + amount;
      },
      0
    );

    if (totalSelectedLedgersAmount > availableBalance) {
      toastError(`The total amount of selected ledgers exceeded the available balance of ${availableBalance}`, {
        toastId: 'ledgersAmountExceededBalance',
        autoClose: 2000,
      });

      return false;
    }

    return true;
  };

  const handleSubmit = async () => {
    if (!validateSelectedLedgers()) {
      return;
    }

    const selectedLedgerIds = map(selectedLedgers, 'id');

    try {
      setIsLoading(true);

      const { data } = await createPayoutHandler({
        variables: {
          id,
          currency,
          requestIdType,
          ledgers: selectedLedgerIds,
          bankAccountId: selectedBankAccount,
        },
      });

      setIsLoading(false);

      if (actionError) {
        return;
      }

      const createdPayoutId = get(data, 'createPayoutFromLedgers.payoutId');

      if (createdPayoutId) {
        setOpen(false);
        onSubmit();

        const linkToNewPayout = `/account/${encodeURIComponent(id)}/type/${encodeURIComponent(
          requestIdType
        )}/payout/${encodeURIComponent(createdPayoutId)}`;

        history.push(linkToNewPayout);
      }
    } catch (e) {
      // eslint-disable-no-empty
    } finally {
      setIsLoading(false);
    }
  };

  const onCreatePayoutClicked = () => {
    const selectedLedgersAreValid = validateSelectedLedgers();

    if (selectedLedgersAreValid) {
      setOpen(true);
    }
  };

  const bankAccountDropdownData = getBankAccountDropdownData(bankAccounts);

  return (
    <ActionButtonContainer>
      <Button variant="outlined" disabled={isEmpty(selectedLedgers)} onClick={onCreatePayoutClicked}>
        Create payout
      </Button>
      <Dialog open={open} maxWidth="lg">
        <Backdrop sx={{ color: '#ccc', zIndex: 100 }} open={isLoading}>
          <CircularProgress color="inherit" />
        </Backdrop>
        <DialogTitle>Create payout from ledgers</DialogTitle>
        <DialogContent sx={{ minWidth: '800px' }}>
          <DialogContentText>
            <SelectorContainer>
              <SelectorLabel>Select Bank Account</SelectorLabel>
              {areBankAccountsLoading ? (
                <CircularProgress color="inherit" size={20} />
              ) : (
                <AccountSelector
                  bankAccounts={bankAccountDropdownData}
                  selectedBankAccount={selectedBankAccount}
                  setBankAccount={setBankAccount}
                />
              )}
            </SelectorContainer>
            <SelectorContainer>
              <SelectorLabel>Select Currency</SelectorLabel>
              <CurrencySelectorWrapper>
                <Dropdown
                  fullWidth
                  items={supportedCurrencyOptions}
                  onChange={e => setCurrency(e.target.value as string)}
                  value={currency}
                />
              </CurrencySelectorWrapper>
            </SelectorContainer>
            <h4>Please confirm the below entries to create a payout</h4>
            <TableContainer component={Paper}>
              <MuiTable sx={{ minWidth: 500 }} size="small">
                <TableHead>
                  <TableRow>
                    <TableCell align="center">Ledger ID</TableCell>
                    <TableCell align="right">Ledger amount</TableCell>
                    <TableCell align="center">Ledger type</TableCell>
                    <TableCell />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {selectedLedgers.map(ledger => (
                    <TableRow key={ledger.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                      <TableCell>{ledger.id}</TableCell>
                      <TableCell align="right">{ledger.amount}</TableCell>
                      <TableCell align="right">{ledger.type}</TableCell>
                      <TableCell sx={{ padding: '5px' }}>
                        <IconButton
                          color="secondary"
                          onClick={() => {
                            // eliminates unwanted effect of modal appearance
                            // after deleting all items when a new one is selected
                            if (selectedLedgers.length === 1) {
                              setOpen(false);
                            }

                            removeLedger(ledger);
                          }}
                        >
                          <HighlightOffIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </MuiTable>
            </TableContainer>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button disabled={isLoading} onClick={handleClose}>
            Cancel
          </Button>
          <Button disabled={isLoading} onClick={handleSubmit}>
            Create Payout
          </Button>
        </DialogActions>
      </Dialog>
    </ActionButtonContainer>
  );
};

const SelectorLabel = styled('h4')`
  margin-right: 12px;
  min-width: 170px;
`;

const SelectorContainer = styled('div')`
  align-items: center;
  display: flex;
`;

const StrongText = styled('span')`
  color: #777;
  font-weight: bold;
`;

const CurrencySelectorWrapper = styled('div')`
  margin: 4px;
  width: 75px;
`;

export default CreatePayoutFromLedgers;
