import { ReactElement, useState } from 'react';
import Joi from 'joi';
import { Box, styled } from '@mui/material';
import { get, isEmpty } from 'lodash';
import { joiResolver } from '@hookform/resolvers/joi';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { useMutation } from '@apollo/client';

import Backdrop from '@mui/material/Backdrop';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import DropdownField from 'src/components/form/fields/DropdownField';
import InputField from 'src/components/form/fields/InputField';
import { AccountType } from 'src/constants/accountConstants';
import { clearLedgersCache } from 'src/services/apollo/helpers/cacheHelpers';
import { FilterValuesType } from 'src/constants/filterConstants';
import { convertDecimalsToCents, getOptions } from 'src/helpers/commonHelpers';
import { SUPPORTED_CURRENCIES } from 'src/constants/commonConstants';

const supportedCurrencyOptions = getOptions(SUPPORTED_CURRENCIES);

export interface AdjusterProps {
  disabled: boolean;
  filters?: FilterValuesType | null;
}

interface CommonAdjustmentsProps {
  action: 'adjustCredit' | 'adjustDebit';
  actionMutation: any; // TODO add proper type
  description?: ReactElement;
  disabled: boolean;
  filters?: FilterValuesType | null;
  title: string;
}

const CommonAdjustments = ({
  action,
  actionMutation,
  description,
  disabled,
  filters,
  title,
}: CommonAdjustmentsProps) => {
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const requestIdType = get(filters, 'requestIdType', '');
  const accountId = get(filters, requestIdType, '');

  return (
    <ActionContainer>
      <Button disabled={disabled} onClick={handleClickOpen} variant="outlined">
        {title}
      </Button>
      <AdjusterContent
        action={action}
        accountId={accountId}
        actionMutation={actionMutation}
        description={description}
        open={open}
        requestIdType={requestIdType}
        setOpen={setOpen}
        title={title}
      />
    </ActionContainer>
  );
};

const ActionContainer = styled('div')`
  display: flex;
  justify-content: right;
  margin-bottom: 8px;
  padding-left: 8px;
`;

interface AdjusterContentProps {
  action: 'adjustCredit' | 'adjustDebit';
  accountId: string;
  actionMutation: any;
  description?: ReactElement;
  open: boolean;
  requestIdType: AccountType | '';
  setOpen: (open: boolean) => void;
  title: string;
}

const AdjusterContent = ({
  action,
  actionMutation,
  accountId,
  description,
  open,
  requestIdType,
  setOpen,
  title,
}: AdjusterContentProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();
  const [adjustmentHandler, { error: updateError }] = useMutation(actionMutation, {
    update: clearLedgersCache,
  });

  const adjusterFormSchema = Joi.object({
    amount: Joi.number()
      .positive()
      .required()
      .messages({
        'number.custom': 'Should be a non-empty positive number with no more than 2 digits after the dot',
      })
      .custom((value, helpers) => {
        const decimals = value.toString().split('.');
        const numberOfDecimals = get(decimals, '1.length', 0);

        if (numberOfDecimals > 2) {
          return helpers.error('number.custom');
        }

        return value;
      }),
    currency: Joi.string().trim().required(),
    refId: Joi.string().trim().required().messages({
      'string.empty': 'Should not be empty',
    }),
    notes: Joi.string().trim().max(1000),
  }).options({ stripUnknown: true });

  const {
    control,
    formState: { isDirty, errors },
    getValues,
    reset,
  } = useForm({
    defaultValues: {
      amount: '',
      currency: 'USD',
      refId: '',
      notes: '',
    },
    resolver: joiResolver(adjusterFormSchema),
    mode: 'onChange',
  });

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = async () => {
    try {
      setIsLoading(true);

      const { refId, amount, currency, notes } = getValues();

      const amountInCents = convertDecimalsToCents(amount);

      const { data } = await adjustmentHandler({
        variables: {
          id: accountId,
          requestIdType,
          refId,
          amount: amountInCents,
          currency,
          notes,
        },
      });

      setIsLoading(false);

      if (updateError) {
        return;
      }

      reset();
      setOpen(false);

      const createdLedgerId = get(data, `${action}.ledgerId`);

      if (createdLedgerId) {
        const linkToNewLedger = `/account/${encodeURIComponent(accountId)}/type/${encodeURIComponent(
          requestIdType
        )}/ledger/${encodeURIComponent(createdLedgerId)}`;

        history.push(linkToNewLedger);
      }
    } catch (e) {
      // eslint-disable-no-empty
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Dialog fullWidth open={open} onClose={handleClose}>
      <Backdrop sx={{ color: '#ccc', zIndex: 100 }} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent sx={{ minWidth: 300 }}>
        {description}
        <Box sx={{ my: 3 }}>
          <InputField
            autoFocus
            control={control}
            disabled={isLoading}
            error={!!errors?.refId}
            helperText={errors?.refId?.message}
            key="refId"
            label="Reference Id"
            name="refId"
            required
            trimSpaces
            type="text"
            variant="outlined"
          />
        </Box>
        <MoneyInputWrapper>
          <InputField
            control={control}
            disabled={isLoading}
            error={!!errors?.amount}
            helperText={errors?.amount?.message}
            key="amount"
            label="Amount (dollars)"
            name="amount"
            required
            trimSpaces
            type="number"
            variant="outlined"
          />
          <CurrencySelectorWrapper>
            <DropdownField
              control={control}
              items={supportedCurrencyOptions}
              key="currency"
              label="Currency"
              name="currency"
            />
          </CurrencySelectorWrapper>
        </MoneyInputWrapper>
        <Box sx={{ my: 3 }}>
          <InputField
            control={control}
            disabled={isLoading}
            error={!!errors?.notes}
            helperText={errors?.notes?.message}
            key="notes"
            label="Notes"
            multiline
            name="notes"
            rows={3}
            type="number"
            variant="outlined"
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button disabled={isLoading} onClick={handleClose}>
          Cancel
        </Button>
        <Button disabled={!isDirty || !isEmpty(errors) || isLoading} onClick={handleSubmit}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const CurrencySelectorWrapper = styled('div')`
  display: flex;
  justify-content: right;
  min-width: 80px;
`;

const MoneyInputWrapper = styled('div')`
  display: flex;
`;

export default CommonAdjustments;
