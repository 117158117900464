import { useState } from 'react';
import styled from '@emotion/styled';
import { map } from 'lodash';

import {
  Box,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  ListSubheader,
  TextField,
  InputAdornment,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

interface DropdownWithSearchProps {
  disabledOptions?: string[];
  label?: string;
  loading?: boolean;
  onSelectHandler: (selectedOption: string) => void;
  options: { label: string; value: string }[] | null;
  searchPlaceholder?: string;
  searchFunction: (searchString: string) => void;
}

export default function DropdownWithSearch({
  disabledOptions = [],
  label = '',
  loading,
  options = [],
  onSelectHandler,
  searchPlaceholder = '',
  searchFunction,
}: DropdownWithSearchProps) {
  const [selectedOption, setSelectedOption] = useState('');
  const [searchText, setSearchText] = useState('');

  const onSearchHandler = () => searchFunction(searchText);

  const dropdownContent = loading ? (
    <ListSubheader>Loading...</ListSubheader>
  ) : (
    map(options, ({ label, value }, index) => (
      <MenuItem key={index} value={value} disabled={!!value && disabledOptions.includes(value)}>
        {label}
      </MenuItem>
    ))
  );

  return (
    <Box sx={{ my: 2 }}>
      <FormControl fullWidth>
        <StyledInputLabel id="search-select-label">{label}</StyledInputLabel>
        <Select
          MenuProps={{ autoFocus: false }} // disables auto focus on MenuItems and allows TextField to be in focus
          defaultValue="" // fixes 'You have provided an out-of-range value' warning
          labelId="search-select-label"
          id="search-select"
          value={selectedOption}
          label={label}
          onChange={e => {
            const selectedValue = e.target.value as string;

            setSelectedOption(selectedValue);
            onSelectHandler(selectedValue);
          }}
        >
          <ListSubheader>
            <TextField
              size="small"
              autoFocus
              placeholder={searchPlaceholder}
              fullWidth
              InputProps={{
                endAdornment: (
                  <>
                    {searchText.length > 0 ? (
                      <InputAdornment position="end">
                        <ResetButton disabled={loading} onClick={() => setSearchText('')}>
                          <CloseIcon />
                        </ResetButton>
                      </InputAdornment>
                    ) : null}
                    <InputAdornment position="end">
                      <SearchButton
                        color="primary"
                        disabled={searchText.length === 0 || loading}
                        disableElevation
                        onClick={onSearchHandler}
                        variant="contained"
                      >
                        <SearchIcon />
                      </SearchButton>
                    </InputAdornment>
                  </>
                ),
              }}
              onChange={e => setSearchText(e.target.value)}
              onKeyDown={e => {
                if (e.key === 'Enter') {
                  onSearchHandler();
                }

                if (e.key !== 'Escape') {
                  // Prevents autoselecting item while typing (default Select behaviour)
                  e.stopPropagation();
                }
              }}
              value={searchText}
            />
          </ListSubheader>
          {dropdownContent}
        </Select>
      </FormControl>
    </Box>
  );
}

const SearchButton = styled(Button)`
  min-width: 32px;
  position: relative;
  right: -14px;
  min-height: 38px;
`;

const StyledInputLabel = styled(InputLabel)`
  top: -6px;
`;

const ResetButton = styled(IconButton)`
  position: absolute;
  right: 56px;
`;
