import { get } from 'lodash';
import { stringifyDate } from 'src/helpers/dateTimeHelpers';

import { CopyToClipboardByClick } from 'src/components/common/CopyToClipboard/CopyToClipboard';

export const accountPayoutsColumnsConfig = [
  {
    attr: 'id',
    label: 'Payout ID',
    customRenderer: (rowItem: Record<string, any>) => <CopyToClipboardByClick text={rowItem.id} />,
  },
  {
    attr: 'transferId',
    label: 'Transfer Id',
    customRenderer: (rowItem: Record<string, any>) => <CopyToClipboardByClick text={rowItem.transferId} />,
  },
  {
    attr: 'transferStatus',
    label: 'Transfer Status',
  },
  {
    attr: 'subTotalAmount',
    label: 'SubTotal Amount',
    customColumnStyle: () => ({ textAlign: 'right' }),
  },
  {
    attr: 'payoutAmount',
    label: 'Payout Amount',
    customColumnStyle: () => ({ textAlign: 'right' }),
  },
  {
    attr: 'currency',
    label: 'Currency',
    customColumnStyle: () => ({ width: 100 }),
  },
  {
    attr: 'payoutTime',
    label: 'Payout Time',
    customRenderer: (item: any) => stringifyDate(get(item, 'payoutTime')),
    customColumnStyle: () => ({ width: 170 }),
  },
  {
    attr: 'effectiveAt',
    label: 'Effective At',
    customRenderer: (item: any) => stringifyDate(get(item, 'effectiveAt')),
    customColumnStyle: () => ({ width: 170 }),
  },
];
