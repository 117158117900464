import React, { useController } from 'react-hook-form';
import { FormControlLabel, Radio, RadioGroup as MuiRadioGroup } from '@mui/material';
import styled from 'styled-components';
import { FormFieldType } from './../formTypes';

type RadioGroupOption = {
  value: string;
  label: string;
};

interface TextFieldType extends FormFieldType {
  defaultValue?: string;
  options: RadioGroupOption[];
}

const generateRadioOptions = (options: RadioGroupOption[]) =>
  options.map(singleOption => (
    <StyledFormControlLabel
      control={<Radio />}
      key={singleOption.value}
      label={singleOption.label}
      value={singleOption.value}
    />
  ));

const RadioGroupField = ({ name, control, defaultValue = '', options, rules }: TextFieldType) => {
  const {
    field: { onChange, value },
  } = useController({
    name,
    control,
    rules,
    defaultValue,
  });

  return (
    <MuiRadioGroup row value={value} onChange={onChange}>
      {generateRadioOptions(options)}
    </MuiRadioGroup>
  );
};

const StyledFormControlLabel = styled(FormControlLabel)`
  & .MuiFormControlLabel-label {
    font-size: 14px;
  }
`;

export default RadioGroupField;
