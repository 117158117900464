import Button from '@mui/material/Button';
import styled from 'styled-components';
import { get } from 'lodash';
import { useForm } from 'react-hook-form';

import InputField from 'src/components/form/fields/InputField';
import RadioGroupField from 'src/components/form/fields/RadioGroupField';
import useUserData from 'src/context/UserContext';
import { AccountType } from 'src/constants/accountConstants';
import { FilledContainer } from 'src/components/layouts/Layout';
import { FilterValuesType, REQUEST_ID_TYPE_KEY } from 'src/constants/filterConstants';
import { FormActions, PrimaryButton } from 'src/components/form/FormLayout';

interface FilterSectionProps {
  filters?: FilterValuesType | null;
  onSubmit: (filterValues: FilterValuesType) => void;
  onReset: (filterValues: FilterValuesType) => void;
}

const AccountFilters = ({ filters, onReset, onSubmit }: FilterSectionProps) => {
  const { userAbility } = useUserData();

  const isPrivilegedRole = userAbility.can('see', 'sensitiveInfo');

  const accountFiltersDefaultValues: FilterValuesType = {
    [AccountType.account]: '',
    [AccountType.business]: '',
    [REQUEST_ID_TYPE_KEY]: isPrivilegedRole ? AccountType.account : AccountType.business,
  };

  const {
    control,
    formState: { isDirty },
    getValues,
    handleSubmit,
    reset,
    watch,
  } = useForm({
    defaultValues: {
      ...accountFiltersDefaultValues,
      ...filters,
    },
  });

  const requestIdType = watch(REQUEST_ID_TYPE_KEY) as AccountType;
  const selectedId = watch(requestIdType);

  const idTypeText = requestIdType === AccountType.account ? 'Account ID' : 'Business ID';
  const disableActionButton = (!isDirty && !selectedId) || !selectedId;
  const disableGetButton = disableActionButton || get(filters, filters?.requestIdType || '') === selectedId;

  const onFormSubmit = () => onSubmit(getValues() as FilterValuesType);

  const requestIdTypeSwitcher = isPrivilegedRole ? (
    <RadioGroupField
      control={control}
      name={REQUEST_ID_TYPE_KEY}
      options={[
        { value: AccountType.account, label: 'by Account ID' },
        { value: AccountType.business, label: 'by Business ID' },
      ]}
    />
  ) : null;

  return (
    <FilledContainer>
      <StyledPageHeader>Account by {idTypeText}</StyledPageHeader>
      <form onSubmit={handleSubmit(onFormSubmit)}>
        <InputField
          control={control}
          fullWidth
          key={AccountType[requestIdType]}
          name={AccountType[requestIdType]}
          placeholder={idTypeText}
          required
          trimSpaces
          type="text"
          variant="standard"
        />
        {requestIdTypeSwitcher}
        <FormActions>
          <PrimaryButton disabled={disableGetButton} type="submit" variant="contained" color="primary">
            Get Account
          </PrimaryButton>
          <Button
            disabled={disableActionButton}
            onClick={() => {
              reset(accountFiltersDefaultValues);
              onReset(accountFiltersDefaultValues);
            }}
          >
            Reset
          </Button>
        </FormActions>
      </form>
    </FilledContainer>
  );
};

const StyledPageHeader = styled('h3')`
  font-size: 21px;
  font-weight: 600;
  line-height: 24px;
  width: 100%;
`;

export default AccountFilters;
