import { createContext, ReactElement, ReactNode, useContext, useMemo } from 'react';
import { Ability } from '@casl/ability';
import { get } from 'lodash';
import { useMutation, useQuery } from '@apollo/client';

import CircularLoader from 'src/components/progress/CircularLoader';
import logger from 'src/services/logger';
import { getAbilityFor } from 'src/helpers/userHelpers';
import { SIGN_OUT_MUTATION } from 'src/services/apollo/mutations/userMutations';
import { User, UserAbility } from 'src/constants/userConstants';
import { USER_INFO_QUERY } from 'src/services/apollo/queries/userQueries';

interface UserContextType {
  signOut: () => void;
  user: User;
  userAbility: Ability;
}

const UserContext = createContext<UserContextType>({} as UserContextType);

// The provider to wrap the entire app with it
export function UserProvider({ children }: { children: ReactNode }): ReactElement {
  const { client, data, loading } = useQuery(USER_INFO_QUERY, { fetchPolicy: 'network-only' });
  const [signOutHandler] = useMutation(SIGN_OUT_MUTATION);

  const signOut = async () => {
    try {
      // eslint-disable-next-line no-var
      var {
        data: {
          signOut: { redirectUrl },
        },
      } = await signOutHandler();
    } catch (err: any) {
      logger.error(err?.message);
    }

    await client.clearStore();

    // eslint-disable-next-line block-scoped-var
    if (redirectUrl) {
      // eslint-disable-next-line block-scoped-var
      window.location.href = redirectUrl;
    }
  };

  const userAbilities = get(data, 'userInfo.userAbilities', []) as UserAbility[];

  const memoedValue = useMemo(
    () => ({
      signOut,
      user: get(data, 'userInfo', {}),
      userAbility: getAbilityFor(userAbilities),
    }),
    [data, loading]
  );

  return loading ? <CircularLoader /> : <UserContext.Provider value={memoedValue}>{children}</UserContext.Provider>;
}

// We only want to use the hook `useUserData` directly and never the context component
export default function useUserData(): UserContextType {
  return useContext(UserContext);
}
