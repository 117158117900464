import React, { ReactElement } from 'react';
import { styled } from '@mui/material';

import { FileListType } from 'src/constants/reportConstants';
import { PseudoLink } from '../common/Links';

const FileList = ({ files = [], onFileClick }: FileListType): ReactElement => {
  return (
    <FileListContainer>
      {files.map((file, index) => (
        <FileListItem key={index}>
          <PseudoLink
            onClick={() => {
              onFileClick && onFileClick(file.name);
            }}
          >
            {file.name}
          </PseudoLink>
        </FileListItem>
      ))}
    </FileListContainer>
  );
};

const FileListContainer = styled('div')`
  display: flex;
  flex-direction: column;
`;

const FileListItem = styled('div')`
  padding-bottom: 5px;
`;

export default FileList;
