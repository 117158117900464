import { FC } from 'react';
import { Controller } from 'react-hook-form';

import Dropdown from 'src/components/common/Dropdown/Dropdown';
import { DropdownItemType, LabeledFormFieldType } from './../formTypes';

interface SwitchField extends LabeledFormFieldType {
  fullWidth?: boolean;
  items: DropdownItemType[];
}

const DropdownField: FC<SwitchField> = ({ control, fullWidth, items, label, name }) => {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value } }) => (
        <Dropdown fullWidth={fullWidth} items={items} label={label} onChange={onChange} value={value} />
      )}
    />
  );
};

export default DropdownField;
