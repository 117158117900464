import { get } from 'lodash';
import { useApolloClient } from '@apollo/client';
import { useCallback, useState } from 'react';

import { AggregatedReportType } from '../constants/reportConstants';
import { GET_REPORT_CONTENT_QUERY } from 'src/services/apollo/queries/reportQueries';

export const useReportContent = (reportType: AggregatedReportType) => {
  const apolloClient = useApolloClient();
  const [loading, setLoading] = useState(false);

  const getReportContent = useCallback(
    async (fileName: string) => {
      setLoading(true);

      try {
        const result = await apolloClient.query({
          query: GET_REPORT_CONTENT_QUERY,
          variables: { reportFileName: fileName, reportType },
        });

        return get(result, 'data.reportContent.content');
      } finally {
        setLoading(false);
      }
    },
    [apolloClient, reportType]
  );

  return {
    getReportContent,
    loading,
  };
};
