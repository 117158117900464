export const triggerFileDownload = (fileName: string, content: string) => {
  const blob = new Blob([content], { type: 'text/plain' });
  const url = URL.createObjectURL(blob);
  const link = document.createElement('a');

  link.href = url;
  link.download = fileName;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  URL.revokeObjectURL(url);
};

export const convertFileToBase64 = (file: File): Promise<string> => {
  return new Promise<string>((resolve, reject) => {
    const reader = new FileReader();

    reader.readAsDataURL(file);
    reader.onload = () => {
      const rawString = reader.result?.toString() || '';

      // remove file prefix. e.g. data:text/csv;base64,
      const cleanedString = rawString.slice(rawString.indexOf('base64,') + 'base64,'.length);

      resolve(cleanedString);
    };
    // eslint-disable-next-line @typescript-eslint/prefer-promise-reject-errors
    reader.onerror = error => reject(error);
  });
};
