import { FC } from 'react';
import styled from 'styled-components';

const NotFoundContainer: FC = () => {
  return (
    <StyledNotFoundContainer data-aid="error-boundary-container">
      <Row>
        <Item data-aid="not-found-message">
          <p>Page not found</p>
        </Item>
      </Row>
    </StyledNotFoundContainer>
  );
};

// TODO Make common layout components for NotFound and ErrorBoundary
const StyledNotFoundContainer = styled.div`
  align-items: center;
  display: flex;
  height: 100vh;
  justify-content: center;
  margin: 0;
  padding: 0;
  width: 100vw;
`;

StyledNotFoundContainer.displayName = 'StyledNotFoundContainer';

const Row = styled.div`
  width: auto;
`;

Row.displayName = 'Row';

const Item = styled.div`
  padding: 5px;
  text-align: center;
`;

Item.displayName = 'Item';

export default NotFoundContainer;
