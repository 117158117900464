import styled from '@emotion/styled';
import { Link } from 'react-router-dom';

import PanelRow from '../PanelRow';
import { getTransactionColor, getTransactionType } from 'src/containers/txnLookup/helpers/txnLookupHelpers';
import { stringifyDate } from 'src/helpers/dateTimeHelpers';
import { TxnInfo } from 'src/containers/txnLookup/constants/txnConstants';

const SingleTransactionInfo = ({ data, isActive = false }: { data: TxnInfo; isActive?: boolean }) => {
  const { action, amount, createdAt, status, isSettled } = data || {};
  const transactionType = getTransactionType(data);
  const transactionColor = getTransactionColor(transactionType);

  const settlementStatus = isSettled === 'true' ? 'SETTLED' : isSettled === 'false' ? 'NOT SETTLED' : '';

  return (
    <SingleTransactionWrapper isActive={isActive}>
      <PanelRow label="Type:" value={<span style={{ color: transactionColor }}>{transactionType}</span>} />
      <TxnHeader data={data} idAsUrl={!isActive} />
      <StackedRow>
        <PanelRow label="Status:" value={status} />
        <PanelRow label="Action:" value={action} />
      </StackedRow>
      <PanelRow label="Amount:" value={amount} />
      <PanelRow label="Created:" value={stringifyDate(createdAt)} />
      <PanelRow label="Settlement Status:" value={settlementStatus} />
    </SingleTransactionWrapper>
  );
};

const TxnHeader = ({ data, idAsUrl }: { data: TxnInfo; idAsUrl?: boolean; textColor?: string }) => {
  const id = data?.id || '';
  const txnId = idAsUrl ? <TxnLink to={`/txn-lookup/${encodeURIComponent(id)}`}>{id}</TxnLink> : id;

  return <PanelRow label="ID:" value={<TxnValue>{txnId}</TxnValue>} />;
};

export const TxnLink = styled(Link)<{ color?: string }>`
  color: ${props => props.color || '#2c4370'};
  text-decoration: none;

  &:hover {
    color: ${props => props.color || '#3e6fa3'};
    opacity: 0.8;
    text-decoration: underline;
  }
`;

const SingleTransactionWrapper = styled('div')<{ isActive: boolean }>`
  background-color: ${props => (props.isActive ? '#fff' : '#fff')};
  border-width: 2px;
  border-style: solid;
  border-color: ${props => (props.isActive ? '#666' : '#fff')};
  box-shadow: rgba(50, 50, 93, ${props => (props.isActive ? '0.5' : '0.25')}) 0 2px 5px -1px,
    rgba(0, 0, 0, ${props => (props.isActive ? '0.6' : '0.3')}) 0 1px 3px -1px;
  border-radius: 4px;
  padding: 8px 0 8px 12px;
`;

const TxnValue = styled('span')`
  font-weight: 600;
`;

const StackedRow = styled('div')`
  display: flex;
  padding-right: 12px;
`;

export default SingleTransactionInfo;
