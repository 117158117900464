import { gql } from '@apollo/client';

export const GET_REPORT_FILES_QUERY = gql`
  query getReportFiles($reportType: AggregatedReportType!) {
    reportList(reportType: $reportType) {
      name
    }
  }
`;

export const GET_REPORT_CONTENT_QUERY = gql`
  query getReportContent($reportFileName: String!, $reportType: AggregatedReportType!) {
    reportContent(reportFileName: $reportFileName, reportType: $reportType) {
      content
    }
  }
`;
