import { styled } from '@mui/material';

export const PseudoLink = styled('span')`
  text-decoration: underline;
  color: #1976d2;
  text-decoration-color: rgba(25, 118, 210, 0.4);

  &:hover {
    cursor: pointer;
    text-decoration-color: rgba(25, 118, 210, 1);
  }
`;
