import styled from '@emotion/styled';
import { get } from 'lodash';
import { ReactElement, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import GraphPanel from './components/GraphPanel';
import InfoPanel from './components/infoPanel/InfoPanel';
import Search from './components/infoPanel/components/Search';
import useTxnData from './hooks/useTxnData';
import { TxnLookupProvider } from './TxnLookupContext';

const TxnLookup = (): ReactElement => {
  const history = useHistory();
  const urlParams = useParams();

  const txnIdFromUrl = get(urlParams, 'txnId', '');
  const [txnSearchId, setTxnSearchId] = useState(decodeURIComponent(txnIdFromUrl));

  const { data, getTxnData, isLoading } = useTxnData();
  const hasTxnData = !!get(data, 'transactionData.baseTxn.id');
  const hasEmptyResponse = txnSearchId && !isLoading && !hasTxnData;

  useEffect(() => {
    if (txnIdFromUrl) {
      setTxnSearchId(txnIdFromUrl);
      getTxnData(txnIdFromUrl);
    }
  }, [txnIdFromUrl]);

  const onSearchSubmit = (txnId: string) => {
    history.push(`/txn-lookup/${encodeURIComponent(txnId)}`);
  };

  return (
    <PageContainer>
      <TxnLookupProvider>
        <PanelWrapper>
          <Search
            loading={isLoading}
            onSubmit={onSearchSubmit}
            setTxnSearchId={setTxnSearchId}
            txnSearchId={txnSearchId}
          />
          {hasTxnData ? <InfoPanel fullTxnData={data} baseTxnId={txnSearchId} /> : null}
        </PanelWrapper>
        {hasTxnData ? (
          <GraphPanel data={data} baseTxnId={txnSearchId} />
        ) : hasEmptyResponse ? (
          <NoDataFoundWrapper>No data found</NoDataFoundWrapper>
        ) : null}
      </TxnLookupProvider>
    </PageContainer>
  );
};

const PageContainer = styled('div')`
  display: flex;
  min-height: calc(100vh - 80px);
  padding-left: 12px;
  padding-right: 12px;
  width: 100%;
`;

const PanelWrapper = styled('div')`
  width: 410px;
  padding-right: 12px;
`;

const NoDataFoundWrapper = styled('div')`
  display: flex;
  flex-basis: calc(100% - 400px);
  justify-content: center;
  padding-top: 40px;
`;

export default TxnLookup;
