import { useApolloClient } from '@apollo/client';
import { useState } from 'react';

import { SINGLE_ACCOUNT_QUERY } from 'src/services/apollo/queries/accountQueries';
import { FilterValuesType, REQUEST_ID_TYPE_KEY } from 'src/constants/filterConstants';

export default function useAccountData() {
  const [isLoading, setIsLoading] = useState(false);
  const [accountData, setAccountData] = useState(null);

  const apolloClient = useApolloClient();

  const getAccountData = async (filterValues: FilterValuesType | null) => {
    try {
      if (!filterValues) {
        return;
      }

      setIsLoading(true);

      const requestIdType = filterValues[REQUEST_ID_TYPE_KEY];

      const { data } = await apolloClient.query({
        query: SINGLE_ACCOUNT_QUERY,
        variables: { requestIdType, id: filterValues[requestIdType] },
      });

      if (data) {
        setAccountData(data.singleAccount);
      }
    } catch (e) {
      setAccountData(null);
    } finally {
      setIsLoading(false);
    }
  };

  return { getAccountData, accountData, isLoading };
}
