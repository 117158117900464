import Button from '@mui/material/Button';
import styled from '@emotion/styled';
import { filter, get, isEmpty } from 'lodash';
import { ReactElement, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';

import Dropdown from 'src/components/common/Dropdown/Dropdown';
import logger from 'src/services/logger';
import Spinner, { SpinnerContainer } from 'src/components/spinner/Spinner';
import Table from 'src/components/table/Table';
import { CREATE_INCENTIVE_MUTATION } from 'src/services/apollo/mutations/incentiveMutation';
import { INCENTIVE_LIST_QUERY } from 'src/services/apollo/queries/incentiveQueries';
import { Incentive, IncentiveStatus } from 'src/constants/accountConstants';
import { IncentivesToGrantDropdownList } from './incentiveConstants';
import { stringifyDate } from 'src/helpers/dateTimeHelpers';

interface IncentivesInfoProps {
  ownerId: string;
}

export default function IncentiveInfo({ ownerId }: IncentivesInfoProps): ReactElement | null {
  const { data: incentiveResponse, loading: isLoading } = useQuery(INCENTIVE_LIST_QUERY, {
    variables: { businessId: ownerId },
  });

  if (isLoading) {
    return (
      <SectionSpinnerContainer>
        <Spinner />
      </SectionSpinnerContainer>
    );
  }

  const incentives = get(incentiveResponse, 'incentiveList', []);

  const incentivesTable =
    !incentives || isEmpty(incentives) ? null : (
      <Table
        columnsConfig={[
          {
            attr: 'status',
            label: 'Status',
            customRenderer: ({ status }) => (status === 'ACTIVE' ? <strong>{status}</strong> : status),
          },
          {
            attr: 'name',
            label: 'Name',
          },
          {
            attr: 'totalGranted',
            label: 'Total Granted',
          },
          {
            attr: 'totalUsed',
            label: 'Total Used',
          },
          {
            attr: 'totalRemaining',
            label: 'Total Remaining',
          },
          {
            attr: 'endDate',
            label: 'End Date',
            customRenderer: item => stringifyDate(get(item, 'endDate')),
          },
        ]}
        rows={incentives}
      />
    );

  return (
    <>
      {incentivesTable}
      <AwardIncentive incentives={incentives} businessId={ownerId} />
    </>
  );
}

const AwardIncentive = ({
  incentives = [],
  businessId,
}: {
  incentives?: Incentive[];
  businessId: string;
}): ReactElement => {
  const [selectedIncentive, setSelectedIncentive] = useState('');

  const [createIncentiveHandler, { loading }] = useMutation(CREATE_INCENTIVE_MUTATION, {
    refetchQueries: [
      {
        query: INCENTIVE_LIST_QUERY,
        variables: { businessId },
      },
    ],
    awaitRefetchQueries: true,
  });

  const activeIncentives = filter(incentives, ({ status }) => status === IncentiveStatus.ACTIVE) as Incentive[];

  if (!isEmpty(activeIncentives)) {
    return <EmptyIncentive>No incentives available to grant</EmptyIncentive>;
  }

  const createIncentive = async () => {
    try {
      const { data } = await createIncentiveHandler({
        variables: {
          incentiveId: selectedIncentive,
          businessId,
        },
      });

      logger.debug(`Created Incentive with id: ${data?.createIncentive?.id}`);
    } catch (error) {
      logger.warn('Error creating incentive');
    }
  };

  return (
    <AwardIncentiveWrapper>
      <ActionRow>
        <DropdownWrapper>
          <Dropdown
            disabled={loading}
            items={[{ label: 'None', value: '' }, ...IncentivesToGrantDropdownList]}
            fullWidth
            label="Award Incentive"
            onChange={e => setSelectedIncentive(e.target.value as string)}
            value={selectedIncentive}
          />
        </DropdownWrapper>
        <Button disabled={!selectedIncentive || loading} onClick={createIncentive}>
          Submit
        </Button>
        {loading ? (
          <InlineSpinnerContainer>
            <Spinner />
          </InlineSpinnerContainer>
        ) : null}
      </ActionRow>
      <InfoRow>Incentives must not be awarded to Reseller c1s - GDP only</InfoRow>
    </AwardIncentiveWrapper>
  );
};

const AwardIncentiveWrapper = styled('div')`
  color: #ff6464;
`;

const InfoRow = styled('div')``;

const ActionRow = styled('div')`
  padding: 16px 0 8px;
  display: flex;
`;

const DropdownWrapper = styled('div')`
  padding-right: 12px;
  width: 50%;
  min-width: 300px;
`;

const SectionSpinnerContainer = styled(SpinnerContainer)`
  height: 100px;
`;

const InlineSpinnerContainer = styled('span')`
  display: flex;
  padding-left: 8px;
`;

const EmptyIncentive = styled('p')`
  color: #888;
  padding-top: 8px;
  font-size: 14px;
  font-weight: 600;
`;
