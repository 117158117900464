import { ReactElement } from 'react';
import { get } from 'lodash';
import { useQuery } from '@apollo/client';

import Table from 'src/components/table/Table';
import { ACCOUNT_BALANCES_QUERY } from 'src/services/apollo/queries/accountQueries';
import { stringifyDate } from 'src/helpers/dateTimeHelpers';

interface AccountLedgersProps {
  // TODO Add proper type
  accountData?: any;
}

export default function AccountBalances({ accountData }: AccountLedgersProps): ReactElement {
  const accountId = get(accountData, 'id', '');
  const isLogicalAccount = get(accountData, 'type') === 'LOGICAL';

  const { loading, data: response } = useQuery(ACCOUNT_BALANCES_QUERY, {
    variables: {
      accountId,
    },
  });

  if (!isLogicalAccount) {
    return <div>Balances are available only for accounts of logical type</div>;
  }

  const items = get(response, 'accountBalances') || [];

  return (
    <>
      <Table
        columnsConfig={[
          {
            attr: 'type',
            label: 'Type',
          },
          {
            attr: 'amount',
            label: 'amount',
            customColumnStyle: () => ({ textAlign: 'right' }),
          },
          {
            attr: 'currency',
            label: 'currency',
            customColumnStyle: () => ({ textAlign: 'center' }),
          },
          {
            attr: 'createdAt',
            label: 'created at',
            customRenderer: (item: any) => stringifyDate(get(item, 'createdAt')),
          },
          {
            attr: 'updatedAt',
            label: 'updated at',
            customRenderer: (item: any) => stringifyDate(get(item, 'updatedAt')),
          },
        ]}
        isLoading={loading}
        rows={items}
      />
    </>
  );
}
