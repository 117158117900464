import { get, isEmpty, reduce } from 'lodash';

import { TxnInfo } from '../constants/txnConstants';

enum TXN_TYPES {
  SALES = 'SALES',
  AUTHORIZATION = 'AUTHORIZATION',
  DECLINE = 'DECLINE',
  REFUND = 'REFUND',
  VOID = 'VOID',
}

const TXN_TYPE_MAPPER = [
  {
    type: TXN_TYPES.SALES,
    mappings: [
      { action: 'S', status: 'C' },
      { action: 'S', status: 'P' },
      { action: 'S', status: 'R' },
    ],
  },
  {
    type: TXN_TYPES.AUTHORIZATION,
    mappings: [{ action: 'A', status: 'C' }],
  },
  {
    type: TXN_TYPES.DECLINE,
    mappings: [{ action: 'S', status: 'D' }],
  },
  {
    type: TXN_TYPES.REFUND,
    mappings: [{ action: 'R', status: 'R' }],
  },
  {
    type: TXN_TYPES.VOID,
    mappings: [
      { action: null, status: 'V' }, // 'null' for any action
      { action: 'V', status: null }, // 'null' for any status
    ],
  },
] as const;

const TXN_COLOR_MAPPER = {
  [TXN_TYPES.SALES]: 'green',
  [TXN_TYPES.AUTHORIZATION]: 'blue',
  [TXN_TYPES.DECLINE]: 'darkred',
  [TXN_TYPES.REFUND]: '#ff5957',
  [TXN_TYPES.VOID]: '#595959',
};

export const getTransactionType = (transaction?: TxnInfo) => {
  if (!transaction) {
    return '';
  }

  const { status, action } = transaction || {};

  const typeItem = TXN_TYPE_MAPPER.find(({ mappings }) =>
    mappings.some(
      mapping =>
        (!mapping.action && mapping.status === status) ||
        (!mapping.status && mapping.action === action) ||
        (mapping.action === action && mapping.status === status)
    )
  );

  return typeItem?.type || '';
};

export const getTransactionColor = (transactionType: string) => {
  return get(TXN_COLOR_MAPPER, transactionType, 'inherit');
};

const initDepositNode = (txnData: TxnInfo) => {
  const { deposit } = txnData;

  if (!deposit?.id) {
    return { nodes: [] as any[], edges: [] as any[] };
  }

  return {
    nodes: [
      {
        id: 'deposit',
        data: {
          amount: deposit.amount,
          depositedAt: deposit.depositedAt,
          shortId: (deposit.id || '').substring(0, 16),
          id: deposit.id,
        },
        position: { x: 220, y: 300 },
        type: 'depositNode',
      },
    ],
    edges: [
      {
        id: 'deposit-root',
        source: 'deposit',
        target: 'root',
      },
    ],
  };
};

const initializeConnectedNodes = (txnData: TxnInfo) => {
  const ledgers = txnData?.ledgers || [];

  const nodesState = initDepositNode(txnData);

  if (isEmpty(ledgers)) {
    return nodesState;
  }

  const yMapper = {
    1: [300],
    2: [200, 400],
    3: [150, 300, 450],
    4: [100, 250, 400, 550],
    5: [75, 200, 325, 450, 575],
    6: [60, 180, 300, 420, 540, 660],
    7: [60, 180, 300, 420, 540, 660, 780],
    8: [60, 180, 300, 420, 540, 660, 780, 900],
    9: [60, 180, 300, 420, 540, 660, 780, 900, 1020],
    10: [60, 180, 300, 420, 540, 660, 780, 900, 1020, 1140],
  };

  return reduce(
    ledgers,
    (acc, ledger, index) => {
      const { nodes, edges } = acc;
      const payout = ledger.payout;

      const updatedNodes = [
        ...nodes,
        {
          id: `ledger${index}`,
          data: {
            id: ledger.id,
            amount: ledger.amount,
            ledgeredAt: ledger.ledgeredAt,
            shortId: (ledger.id || '').substring(0, 16),
            type: ledger.type,
          },
          position: { x: 800, y: get(yMapper, `${ledgers.length}.${index}`, 300) },
          type: 'ledgerNode',
        },
        ...(payout?.id
          ? [
              {
                id: `payout${index}`,
                data: {
                  id: payout.id,
                  shortId: (payout.id || '').substring(0, 16),
                  amount: payout.payoutAmount,
                  payoutTime: payout.payoutTime,
                },
                position: { x: 1050, y: get(yMapper, `${ledgers.length}.${index}`, 300) },
                type: 'payoutNode',
              },
            ]
          : []),
      ];

      const updatedEdges = [
        ...edges,
        { id: `root-ledger${index}`, source: 'root', target: `ledger${index}` },
        ...(payout?.id
          ? [
              {
                id: `ledger${index}-payout`,
                source: `ledger${index}`,
                target: `payout${index}`,
              },
            ]
          : []),
      ];

      return {
        nodes: updatedNodes,
        edges: updatedEdges,
      };
    },
    nodesState
  );
};

export const initializeNodes = (txnData: TxnInfo) => {
  const txnType = getTransactionType(txnData);
  const transactionColor = getTransactionColor(txnType);

  const { nodes, edges } = initializeConnectedNodes(txnData);

  return {
    nodes: [
      {
        id: 'root',
        data: {
          id: (txnData.id || '').substring(0, 8),
          amount: txnData.amount,
          color: transactionColor,
          createdAt: txnData.createdAt,
          hasDeposit: !!txnData.deposit?.id,
          type: txnType,
        },
        position: { x: 500, y: 298 }, // 300-2, compensating for border width
        type: 'rootNode',
      },
      ...nodes,
    ],
    edges,
  };
};
