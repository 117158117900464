import { get, map } from 'lodash';
import { useHistory } from 'react-router-dom';

import { StyledTableCell, StyledTableRow, TableColumnConfigProps } from '../Table';
import { ReactElement } from 'react';

interface RowProps {
  columnConfig: TableColumnConfigProps[];
  editorRouteBase?: string;
  item: any;
}

const getRowCells = (columnConfig: TableColumnConfigProps[], item: any): ReactElement => {
  return (
    <>
      {map(columnConfig, ({ attr, customColumnStyle }) => {
        const attrConfig = columnConfig.find(c => c.attr === attr);
        const value = (attrConfig?.customRenderer && attrConfig.customRenderer(item)) || get(item, attr);

        return (
          <StyledTableCell key={attr} style={customColumnStyle && customColumnStyle()}>
            {value}
          </StyledTableCell>
        );
      })}
    </>
  );
};

export default function Row(props: RowProps): ReactElement {
  const { columnConfig, editorRouteBase, item } = props;
  const history = useHistory();

  const hasEditor = !!editorRouteBase;
  const onClick = hasEditor
    ? () => {
        const link = `${editorRouteBase}/${encodeURIComponent(item.id)}`;

        history.push(link);
      }
    : undefined;

  return (
    <StyledTableRow key={item.id} isClickable={hasEditor} onClick={onClick}>
      {getRowCells(columnConfig, item)}
    </StyledTableRow>
  );
}
