import { FC, ReactElement } from 'react';
import logger from 'src/services/logger';

// Initializing Elastic APM RUM
logger.debug('Initializing APM service...');
import('./../services/apmService');

export default function withNetworkHandler<T extends JSX.IntrinsicAttributes>(
  WrappedComponent: FC<T>
): (props: T) => ReactElement {
  return (props: T): ReactElement => {
    window.onunhandledrejection = ({ reason }: PromiseRejectionEvent) => {
      logger.error(`Promise failed: ${reason}`);
    };

    return <WrappedComponent {...props} />;
  };
}
