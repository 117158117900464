import { createContext, ReactElement, ReactNode, useContext, useEffect, useMemo, useState } from 'react';
import { DocumentNode, TypedDocumentNode, useQuery } from '@apollo/client';

export interface GlobalProgressBarContextType {
  isLoading: boolean;
  setIsLoading: (loading: boolean) => void;
}

export const GlobalProgressBarContext = createContext<GlobalProgressBarContextType>({} as GlobalProgressBarContextType);

export function GlobalProgressBarProvider({ children }: { children: ReactNode }): ReactElement {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const memoValue = useMemo(
    () => ({
      isLoading,
      setIsLoading,
    }),
    [isLoading]
  );

  return <GlobalProgressBarContext.Provider value={memoValue}>{children}</GlobalProgressBarContext.Provider>;
}

// To use the hook `useGlobalProgressBar` directly instead of the context component implicitly
export function useGlobalProgressBar(): GlobalProgressBarContextType {
  return useContext(GlobalProgressBarContext);
}

export const useQueryWithLoader = (query: DocumentNode | TypedDocumentNode, options?: Record<string, any>) => {
  const { setIsLoading } = useGlobalProgressBar();
  const { data, loading } = useQuery(query, options);

  useEffect(() => {
    setIsLoading(loading);

    return () => {
      setIsLoading(false);
    };
  }, [loading]);

  return { data, loading };
};
