export type User = {
  email?: string;
  firstName?: string;
  lastName?: string;
  isAuthenticated?: boolean;
  role?: string;
};

export const ROLE = Object.freeze({
  PRIVILEGED: 'Privileged',
  ADMIN: 'Admin',
  ADMIN_WRITE: 'AdminWrite',
  USER: 'User',
});

export const ROLE_LABEL = Object.freeze({
  [ROLE.ADMIN_WRITE]: 'Oberon Admin',
});

export enum Rules {
  Can = 'can',
  Cannot = 'cannot',
}

export interface UserAbility {
  rule: Rules.Can | Rules.Cannot;
  action: 'manage' | 'see';
  scope: 'all' | 'extra' | 'sensitiveInfo';
}
