import styled from '@emotion/styled';

import ChildTransaction from './ChildTransaction';
import RelatedTransactions from './RelatedTransactions';
import SingleTransactionInfo from './SingleTransactionInfo';
import { TxnInfo } from '../../../../constants/txnConstants';

const BaseTransaction = ({
  baseTxnId,
  txnData,
  isActive,
  hasParent,
  childTransactions,
}: {
  baseTxnId: string;
  txnData: TxnInfo;
  isActive: boolean;
  hasParent: boolean;
  childTransactions?: TxnInfo[];
}) => {
  const contentWithLayout = hasParent ? (
    <ShiftedContentWrapper>
      <ShiftedContent>
        <ChildTransaction txnInfo={txnData} isActive={isActive} />
      </ShiftedContent>
    </ShiftedContentWrapper>
  ) : (
    <SingleTransactionInfo data={txnData} isActive={isActive} />
  );

  const relatedTransactionsContent = <RelatedTransactions baseTxnId={baseTxnId} data={childTransactions} />;

  const relatedTransactionsLayout = hasParent ? (
    <ShiftedRelatedTransactionsWrapper>{relatedTransactionsContent}</ShiftedRelatedTransactionsWrapper>
  ) : (
    relatedTransactionsContent
  );

  return (
    <BaseTransactionWrapper>
      {contentWithLayout}
      {relatedTransactionsLayout}
    </BaseTransactionWrapper>
  );
};

const ShiftedContentWrapper = styled('div')`
  margin-top: 8px;
  padding-left: 8px;
  padding-right: 12px;
  overflow: hidden;
`;

const ShiftedRelatedTransactionsWrapper = styled('div')`
  padding-left: 32px;
`;

const ShiftedContent = styled('div')`
  margin-top: -68px;
  margin-bottom: 60px;
  padding-left: 4px;
`;

const BaseTransactionWrapper = styled('div')``;

export default BaseTransaction;
