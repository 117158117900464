import { ReactElement, useCallback } from 'react';
import { styled } from '@mui/material';
import { useHistory } from 'react-router-dom';

import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import Spinner from 'src/components/spinner/Spinner';

interface CommonViewerProps {
  goBackUrl?: string;
  isLoading: boolean;
  content: ReactElement;
}

const CommonViewer = ({ goBackUrl, isLoading, content }: CommonViewerProps): ReactElement => {
  return (
    <ViewerWrapper>
      <BackLink to={goBackUrl} label="Back" />
      {isLoading ? (
        <SpinnerContainer>
          <Spinner />
        </SpinnerContainer>
      ) : (
        <ContentWrapper>{content}</ContentWrapper>
      )}
    </ViewerWrapper>
  );
};

interface BackLinkProps {
  to?: string;
  label: string;
}

const BackLink = ({ to, label }: BackLinkProps): ReactElement => {
  const history = useHistory();

  const onClick = useCallback(() => {
    to ? history.push(to) : history.goBack();
  }, [to, history]);

  return (
    <CustomLink onClick={onClick}>
      <KeyboardArrowLeftIcon />
      <span>{label}</span>
    </CustomLink>
  );
};

const ContentWrapper = styled('div')`
  align-items: flex-start;
  display: flex;
  padding: 0 24px;
  width: 100%;
`;

ContentWrapper.displayName = 'ContentWrapper';

const CustomLink = styled('span')`
  color: #607d8b;
  display: flex;
  align-items: center;
  font-size: 12px;
  left: 0;
  padding: 8px 24px;
  position: absolute;
  text-transform: uppercase;

  &:hover {
    color: #7ca4b3;
    cursor: pointer;
  }

  > span {
    padding-left: 8px;
  }
`;

CustomLink.displayName = 'CustomLink';

const ViewerWrapper = styled('article')`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
`;

ViewerWrapper.displayName = 'ViewerWrapper';

const SpinnerContainer = styled('span')`
  align-items: center;
  display: flex;
  height: min(400px, 50vh);
  justify-content: center;
  width: 100%;
`;

SpinnerContainer.displayName = 'SpinnerContainer';

export default CommonViewer;
