import CommonAdjustments from './CommonAdjustment';
import { CREDIT_ADJUSTMENTS_MUTATION } from 'src/services/apollo/mutations/ledgerMutations';
import { FilterValuesType } from 'src/constants/filterConstants';
import DialogContentText from '@mui/material/DialogContentText';

interface CreditAdjustmentsProps {
  disabled: boolean;
  filters?: FilterValuesType | null;
}

const CreditAdjustments = ({ disabled, filters }: CreditAdjustmentsProps) => {
  return (
    <CommonAdjustments
      action="adjustCredit"
      actionMutation={CREDIT_ADJUSTMENTS_MUTATION}
      description={
        <DialogContentText>
          You can use this form to create a credit entry to a merchant’s ledger balance for various purposes.
          <br />
          Please input a positive value <strong>(in dollars not cents)</strong> in the “Amount” field; the amount will
          be a credit to the merchant’s current balance.
        </DialogContentText>
      }
      disabled={disabled}
      filters={filters}
      title="Credit Adjustments"
    />
  );
};

export default CreditAdjustments;
