import { map } from 'lodash';
import { stringifyDate } from 'src/helpers/dateTimeHelpers';

export const LedgerAttrDefs = [
  {
    id: 'id',
    label: 'Ledger ID',
  },
  {
    id: 'accountId',
    label: 'Account ID',
  },
  {
    id: 'amount',
    label: 'Amount',
  },
  {
    id: 'arnInfo',
    label: 'ARN',
    hideIfEmpty: true,
    customRender: (arnInfo?: Record<string, any>) => {
      const arns = arnInfo?.arns;

      return arns?.length ? (
        <>
          {map(arns, arn => (
            <div>{arn}</div>
          ))}
        </>
      ) : (
        ''
      );
    },
  },
  {
    id: 'availableBalance',
    label: 'Available Balance',
  },
  {
    id: 'currency',
    label: 'Currency',
  },
  {
    id: 'createdAt',
    label: 'Created at',
    customRender: stringifyDate,
  },
  {
    id: 'ledgeredAt',
    label: 'Ledgered at',
    customRender: stringifyDate,
  },
  {
    id: 'notes',
    label: 'Notes',
  },
  {
    id: 'parentId',
    label: 'Parent ID',
  },
  {
    id: 'parentType',
    label: 'Parent Type',
  },
  {
    id: 'payoutId',
    label: 'Payout Id',
  },
  {
    id: 'refId',
    label: 'Ref ID',
  },
  {
    id: 'refType',
    label: 'Ref Type',
  },
  {
    id: 'type',
    label: 'Type',
  },
  {
    id: 'sourceAccount',
    label: 'Source Account',
  },
  {
    id: 'updatedAt',
    label: 'Updated at',
    customRender: stringifyDate,
  },
];
