import { ReactElement } from 'react';
import { get } from 'lodash';
import { useQuery } from '@apollo/client';

import Table from 'src/components/table/Table';
import { REPORT_CONFIGS_QUERY } from 'src/services/apollo/queries/reportingQueries';

export default function ReportConfigs(): ReactElement {
  const { loading, data: response } = useQuery(REPORT_CONFIGS_QUERY, {
    notifyOnNetworkStatusChange: true,
  });

  const items = get(response, 'reportConfigs') || [];

  return (
    <>
      <Table
        columnsConfig={[
          {
            attr: 'id',
            label: 'Id',
            customColumnStyle: () => ({ textAlign: 'center' }),
          },
          {
            attr: 'rootNode',
            label: 'Lens',
            customColumnStyle: () => ({ textAlign: 'center' }),
          },
          {
            attr: 'source',
            label: 'Source',
            customColumnStyle: () => ({ textAlign: 'center' }),
          },
          {
            attr: 'params',
            label: 'Acceptable Parameters',
            customRenderer: (item: any) => get(item, 'params', []).join(', '),
            customColumnStyle: () => ({ textAlign: 'center' }),
          },
          {
            attr: 'fetchFromSource',
            label: 'Fetch From Source',
            customRenderer: (item: any) => (get(item, 'fetchFromSource') ? 'True' : 'False'),
          },
          {
            attr: 'status',
            label: 'Status',
          },
          {
            attr: 'outputFormats',
            label: 'Acceptable Output Formats',
          },
        ]}
        isLoading={loading}
        rows={items}
      />
    </>
  );
}
