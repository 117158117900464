import { gql } from '@apollo/client';

export const INCENTIVE_LIST_QUERY = gql`
  query IncentiveList($businessId: ID!) {
    incentiveList(businessId: $businessId) {
      endDate
      name
      status
      totalGranted
      totalRemaining
      totalUsed
    }
  }
`;
