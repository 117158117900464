import { MouseEvent } from 'react';
import Checkbox from '@mui/material/Checkbox';
import Tooltip from '@mui/material/Tooltip';
import { find, get } from 'lodash';

import { CopyToClipboardByClick } from 'src/components/common/CopyToClipboard/CopyToClipboard';
import { LedgerFilterDefs } from 'src/constants/filterConstants';
import { LedgerType } from '../../../constants/ledgerConstants';
import { stringifyDate } from 'src/helpers/dateTimeHelpers';
import { TooltipButton } from 'src/components/common/Buttons/Buttons';

interface LedgerColumnsConfigProps {
  accountHasNoAvailableBalance: boolean;
  isLogicalAccount: boolean;
  onFilterAction: (filterValue: Record<string, any>) => void;
  onSelectAction: (ledger: LedgerType) => void;
  selectedLedgers: LedgerType[];
  userHasPermissionsToCreatePayoutFromLedgers: boolean;
}

export function getLedgerColumnsConfig({
  accountHasNoAvailableBalance,
  isLogicalAccount,
  onFilterAction,
  onSelectAction,
  selectedLedgers,
  userHasPermissionsToCreatePayoutFromLedgers,
}: LedgerColumnsConfigProps) {
  return [
    {
      attr: '',
      label: '',
      customColumnStyle: () => ({ width: 20, padding: 10 }),
      customRenderer: (ledger: LedgerType) => {
        const ledgerId = ledger.id;
        const hasPayoutId = !!ledger.payoutId;
        const isDisabled = accountHasNoAvailableBalance || hasPayoutId;
        const isChecked = !!find(selectedLedgers, { id: ledgerId });

        return userHasPermissionsToCreatePayoutFromLedgers && !isDisabled ? (
          <Checkbox
            checked={isChecked}
            disabled={isDisabled}
            onClick={e => {
              e.stopPropagation();
              onSelectAction(ledger);
            }}
          />
        ) : null;
      },
    },
    {
      attr: 'id',
      label: 'ledger Id',
      customColumnStyle: () => ({ width: 280 }),
      customRenderer: (rowItem: Record<string, any>) => <CopyToClipboardByClick text={rowItem.id} />,
    },
    {
      attr: 'type',
      label: 'Type',
      customRenderer: (item: Record<string, any>) => {
        return (
          <Tooltip title="Click to add the value to the filters" arrow enterDelay={500}>
            <TooltipButton
              onClick={(event: MouseEvent<HTMLButtonElement>) => {
                event.stopPropagation();
                onFilterAction({ [LedgerFilterDefs.types]: item?.type });
              }}
            >
              {item?.type}
            </TooltipButton>
          </Tooltip>
        );
      },
    },
    {
      attr: 'refType',
      label: 'ref Type',
      customRenderer: (item: Record<string, any>) => {
        return (
          <Tooltip title="Click to add the value to the filters" arrow enterDelay={500}>
            <TooltipButton
              onClick={(event: MouseEvent<HTMLButtonElement>) => {
                event.stopPropagation();
                onFilterAction({ [LedgerFilterDefs.refTypes]: item?.refType });
              }}
            >
              {item?.refType}
            </TooltipButton>
          </Tooltip>
        );
      },
    },
    {
      attr: 'refId',
      label: 'ref id',
      customColumnStyle: () => ({ width: 280 }),
      customRenderer: (rowItem: Record<string, any>) => <CopyToClipboardByClick text={rowItem.refId} />,
    },
    {
      attr: 'amount',
      label: 'amount',
      customColumnStyle: () => ({ textAlign: 'right' }),
    },
    {
      attr: 'currency',
      label: 'currency',
      customColumnStyle: () => ({ width: '80px', textAlign: 'center' }),
    },
    {
      attr: 'payoutId',
      label: 'payout Id',
      customColumnStyle: () => ({ width: 280 }),
      customRenderer: (rowItem: Record<string, any>) => <CopyToClipboardByClick text={rowItem.payoutId} />,
    },
    ...(isLogicalAccount
      ? [
          {
            attr: 'availableBalance',
            label: 'available Balance',
            customColumnStyle: () => ({ textAlign: 'right' }),
          },
        ]
      : []),
    {
      attr: 'createdAt',
      label: 'created At',
      customRenderer: (item: any) => stringifyDate(get(item, 'createdAt')),
    },
    {
      attr: 'updatedAt',
      label: 'Updated At',
      customRenderer: (item: any) => stringifyDate(get(item, 'updatedAt')),
    },
  ];
}
