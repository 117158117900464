import { get } from 'lodash';
import { useQuery } from '@apollo/client';

import { AggregatedReportType, ReportListType } from '../constants/reportConstants';
import { GET_REPORT_FILES_QUERY } from 'src/services/apollo/queries/reportQueries';

export const useReportList = (reportType: AggregatedReportType) => {
  const { loading, error, data } = useQuery<ReportListType>(GET_REPORT_FILES_QUERY, {
    variables: { reportType },
    fetchPolicy: 'cache-and-network',
  });

  const files = get(data, 'reportList', []);

  return {
    loading,
    error,
    files,
  };
};
