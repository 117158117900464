import { gql } from '@apollo/client';

export const PRICING_PROFILES_BY_NAME_QUERY = gql`
  query PricingProfilesByName($name: String!) {
    pricingProfilesByName(name: $name) {
      id
      name
    }
  }
`;

export const CURRENT_PRICE_PROFILE = gql`
  query PriceProfile($businessId: String!) {
    currentPriceProfile(businessId: $businessId) {
      priceProfileId
      priceProfileName
      inPersonFee
      ecommInvoicingFee
      keyedInFee
      invoicingFee
      paylinksFee
      paybuttonsFee
      payoutNextDay
    }
  }
`;

export const PRICE_PROFILE_INFO = gql`
  query PriceProfile($priceProfileId: String!) {
    priceProfileInfo(priceProfileId: $priceProfileId) {
      priceProfileId
      priceProfileName
      inPersonFee
      ecommInvoicingFee
      keyedInFee
      invoicingFee
      paylinksFee
      paybuttonsFee
      payoutNextDay
    }
  }
`;
