import { ChildTxnWrapper, HierarchyWrapper } from '../InfoPanelLayout';
import SingleTransactionInfo from './SingleTransactionInfo';
import { TxnInfo } from '../../../../constants/txnConstants';

const ChildTransaction = ({ txnInfo, isActive }: { txnInfo: TxnInfo; isActive: boolean }) => {
  return (
    <HierarchyWrapper>
      <ChildTxnWrapper>
        <SingleTransactionInfo data={txnInfo} isActive={isActive} />
      </ChildTxnWrapper>
    </HierarchyWrapper>
  );
};

export default ChildTransaction;
