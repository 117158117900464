import { isEmpty } from 'lodash';
import { ReactElement } from 'react';

import Dropdown from 'src/components/common/Dropdown/Dropdown';
import { DropdownItemType } from 'src/components/form/formTypes';

interface AccountSelectorProps {
  bankAccounts: DropdownItemType[];
  selectedBankAccount: string;
  setBankAccount: (bankAccount: string) => void;
}

const AccountSelector = ({ bankAccounts, selectedBankAccount, setBankAccount }: AccountSelectorProps): ReactElement => {
  return (
    <Dropdown
      disabled={isEmpty(bankAccounts)}
      items={bankAccounts}
      fullWidth
      onChange={e => {
        setBankAccount(e.target.value as string);
      }}
      size="small"
      value={selectedBankAccount}
    />
  );
};

export default AccountSelector;
