import { FC } from 'react';
import Stack from '@mui/material/Stack';

import DateTimePickerField from './DateTimePickerField';

interface DateTimePickerFieldType {
  control: any;
  dateFromLabel: string;
  dateToLabel: string;
  dateFromName: string;
  dateToName: string;
}

const DateTimeRangePickerField: FC<DateTimePickerFieldType> = ({
  control,
  dateFromLabel,
  dateToLabel,
  dateFromName,
  dateToName,
}) => {
  return (
    <Stack direction="row" spacing={2}>
      <DateTimePickerField control={control} label={dateFromLabel} name={dateFromName} />
      <DateTimePickerField control={control} label={dateToLabel} name={dateToName} />
    </Stack>
  );
};

export default DateTimeRangePickerField;
