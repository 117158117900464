import styled from '@emotion/styled';

export const PriceProfileWrapper = styled('div')`
  display: flex;
`;

export const PriceProfileLabel = styled('span')`
  font-weight: 600;
  min-width: 200px;
  padding-right: 16px;
  text-align: right;
`;

export const PriceProfileValue = styled('span')``;
