import { ChangeEvent, useCallback, useRef, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Paper from '@mui/material/Paper/Paper';
import SearchIcon from '@mui/icons-material/Search';
import TextField from '@mui/material/TextField';
import { debounce, noop } from 'lodash';
import { styled } from '@mui/material/styles';

const DEBOUNCE_TIME = 1500;

interface TableSearchProps {
  disabled?: boolean;
  searchFunction?: (search: string) => void;
}

export default function TableSearch({ disabled, searchFunction = noop }: TableSearchProps) {
  const [inputText, setInputText] = useState<string>('');

  // useRef used here instead of useCallback because of the error:
  // "useCallback received a function whose dependencies are unknown. Pass an inline function instead"
  const debouncedSearchFunction = useRef(debounce(inputText => searchFunction(inputText), DEBOUNCE_TIME));

  const handleCloseClick = () => {
    debouncedSearchFunction.current.cancel();
    setInputText('');
    searchFunction('');
  };

  const onChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value || '';

      setInputText(value);
      debouncedSearchFunction.current(value);
    },
    [debouncedSearchFunction]
  );

  // TODO Figure out why 'KeyboardEventHandler` event type doesn't work
  const handleKeyDown = (event: any) => {
    if (event.key === 'Enter') {
      debouncedSearchFunction.current.cancel();
      searchFunction(event.target.value);
    }
  };

  return (
    <SearchContainer>
      <Paper elevation={1}>
        <TextField
          disabled={disabled}
          inputProps={{ style: { WebkitBoxShadow: '0 0 0 30px white inset' } }} // disables the webkit autofill styling
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
            endAdornment: inputText ? (
              <InputAdornment position="end">
                <IconButton onClick={handleCloseClick}>
                  <CloseIcon />
                </IconButton>
              </InputAdornment>
            ) : null,
          }}
          onChange={onChange}
          onKeyDown={handleKeyDown}
          placeholder="Search by Payout ID"
          sx={{ width: '450px' }}
          value={inputText}
          variant="outlined"
        />
      </Paper>
    </SearchContainer>
  );
}

const SearchContainer = styled('div')`
  display: flex;
  justify-content: right;
  margin-bottom: 10px;
  width: 100%;
`;
