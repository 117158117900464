import { MouseEvent, ReactElement } from 'react';
import moment from 'moment';

import ClearIcon from '@mui/icons-material/Clear';
import IconButton from '@mui/material/IconButton';
import MuiTextField, { TextFieldProps } from '@mui/material/TextField';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDateTimePicker } from '@mui/x-date-pickers/MobileDateTimePicker';
import { styled } from '@mui/material/styles';

interface DateTimePickerProps {
  label?: string;
  onBlur?: () => void;
  onChange: (date: Date | null, keyboardInputValue?: string | undefined) => void;
  value: Date | number | string;
}

const DateTimePicker = ({ label, onBlur, onChange, value }: DateTimePickerProps): ReactElement => {
  const handleClearClick = (event: MouseEvent<HTMLSpanElement>) => {
    event.stopPropagation();
    onChange(null);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <MobileDateTimePicker
        renderInput={(props: JSX.IntrinsicAttributes & TextFieldProps) => (
          <StyledMuiTextField
            onBlur={onBlur}
            {...props}
            inputProps={{
              ...props.inputProps,
              readOnly: true,
            }}
            sx={{
              width: '100%',
            }}
            InputProps={{
              endAdornment: (
                <IconButton
                  sx={{ visibility: value ? 'visible' : 'hidden', padding: '4px' }}
                  onClick={handleClearClick}
                >
                  <ClearIcon sx={{ fontSize: '14px' }} />
                </IconButton>
              ),
            }}
          />
        )}
        label={label}
        onChange={(date: any) => {
          // `value` is a moment object so it has to be converted to Date() to support validation
          onChange(moment(date).toDate());
        }}
        value={value}
      />
    </LocalizationProvider>
  );
};

const StyledMuiTextField = styled(MuiTextField)`
  .MuiInputBase-root {
    padding-right: 0;

    .MuiInputBase-input {
      font-size: 12px;
      padding-left: 9px;
    }
  }
`;

export default DateTimePicker;
