import styled from 'styled-components';
import { ReactElement } from 'react';

interface InfoListProps {
  data: {
    label: string;
    value: string;
  }[];
}

const InfoTable = ({ data }: InfoListProps): ReactElement => {
  return (
    <InfoListContainer>
      {data.map(({ label, value }) => {
        return <RowItem key={label} label={label} value={value} />;
      })}
    </InfoListContainer>
  );
};

const RowItem = ({ label, value }: { label: string; value: string }) => {
  return (
    <RowItemContainer>
      <Label>{label}</Label>
      <RowContent>{value}</RowContent>
    </RowItemContainer>
  );
};

const RowItemContainer = styled.div`
  align-items: center;
  display: flex;
  font-size: 13px;
  padding: 0;
`;

const InfoListContainer = styled.div`
  width: 100%;
  ${RowItemContainer}:not(:last-child) {
    border-bottom: 1px solid #ddd;
  }
`;

const Label = styled.div`
  align-items: center;
  color: rgba(0, 0, 0, 0.7);
  display: flex;
  font-weight: 600;
  min-width: 220px;
  margin-right: 16px;
`;

const RowContent = styled.div`
  align-items: center;
  display: flex;
`;

export default InfoTable;
