import { ReactElement, useCallback, useEffect, useState } from 'react';
import { Button, CircularProgress, styled } from '@mui/material';
import { get, has } from 'lodash';
import { useMutation } from '@apollo/client';

import Dropdown from 'src/components/common/Dropdown/Dropdown';
import useUserData from 'src/context/UserContext';
import { SecondaryButton } from 'src/components/common/Buttons/Buttons';
import { SINGLE_ACCOUNT_PAYOUT_QUERY } from 'src/services/apollo/queries/accountQueries';
import { TRANSFER_STATUS_UPDATE_MUTATION } from 'src/services/apollo/mutations/transferMutations';

interface TransferStatusSwitcherProps {
  context: any;
  data?: any;
  status?: string;
}

const SWITCHABLE_STATUSES = {
  ON_HOLD: 'ON_HOLD',
  CANCELLED: 'CANCELLED',
  PENDING: 'PENDING',
  SUBMITTED: 'SUBMITTED',
  FAILED: 'FAILED',
};

const SWITCHABLE_STATUS_MAPPER = {
  ON_HOLD: [SWITCHABLE_STATUSES.ON_HOLD, SWITCHABLE_STATUSES.CANCELLED, SWITCHABLE_STATUSES.PENDING],
  PENDING: [SWITCHABLE_STATUSES.PENDING, SWITCHABLE_STATUSES.ON_HOLD, SWITCHABLE_STATUSES.CANCELLED],
  CANCELLED: [
    SWITCHABLE_STATUSES.CANCELLED,
    SWITCHABLE_STATUSES.ON_HOLD,
    SWITCHABLE_STATUSES.PENDING,
    SWITCHABLE_STATUSES.FAILED,
  ],
  SUBMITTED: [SWITCHABLE_STATUSES.SUBMITTED, SWITCHABLE_STATUSES.FAILED],
};

const TransferStatusSwitcher = ({
  context: variablesToRefetchParentQuery,
  data: transferData = {},
  status: initialStatus = '',
}: TransferStatusSwitcherProps): ReactElement => {
  const [currentStatus, setCurrentStatus] = useState<string>(initialStatus);
  const { userAbility } = useUserData();

  const isPrivilegedRole = userAbility.can('see', 'sensitiveInfo');
  const valueIsChanged = currentStatus !== initialStatus;

  const [updateTransferStatusHandler, { error, loading }] = useMutation(TRANSFER_STATUS_UPDATE_MUTATION, {
    refetchQueries: [
      {
        query: SINGLE_ACCOUNT_PAYOUT_QUERY,
        variables: variablesToRefetchParentQuery,
      },
    ],
    awaitRefetchQueries: true,
  });

  const onApply = async () => {
    try {
      await updateTransferStatusHandler({
        variables: {
          transferId: transferData.id,
          transferUpdateStatus: currentStatus,
        },
      });
    } catch {
      setCurrentStatus(initialStatus);
    }
  };

  const onCancel = useCallback(() => setCurrentStatus(initialStatus), [initialStatus]);

  useEffect(() => {
    if (error) {
      onCancel();
    }
  }, [error, onCancel]);

  if (!isPrivilegedRole || !has(SWITCHABLE_STATUS_MAPPER, initialStatus)) {
    return <span>{initialStatus}</span>;
  }

  const availableStatuses = (get(SWITCHABLE_STATUS_MAPPER, initialStatus, []) as string[]).map(status => ({
    label: status,
    value: status,
  }));

  const actions = valueIsChanged ? (
    <>
      {loading ? (
        <ProgressContainer>
          <CircularProgress size="24px" />
        </ProgressContainer>
      ) : (
        <>
          <Button onClick={onApply}>Apply</Button>
          <SecondaryButton onClick={onCancel}>Cancel</SecondaryButton>
        </>
      )}
    </>
  ) : null;

  return (
    <>
      <SwitcherContent>
        <StyledDropdown>
          <Dropdown
            items={availableStatuses}
            fullWidth
            onChange={e => {
              setCurrentStatus(e.target.value as string);
            }}
            size="small"
            value={currentStatus}
          />
        </StyledDropdown>
        {actions}
      </SwitcherContent>
    </>
  );
};

const ProgressContainer = styled('div')`
  align-items: center;
  display: flex;
  padding-left: 12px;
`;

const SwitcherContent = styled('div')`
  display: flex;
`;

const StyledDropdown = styled('div')`
  margin-right: 12px;
  width: 140px;
`;

export default TransferStatusSwitcher;
