import { FC } from 'react';
import { FormControlLabel, Switch as MuiSwitch } from '@mui/material';
import { Controller } from 'react-hook-form';
import { styled } from '@mui/material/styles';

interface SwitchFieldProps {
  control: any;
  label: string;
  name: string;
}

const SwitchField: FC<SwitchFieldProps> = ({ control, label, name }) => {
  return (
    <SwitchFieldWrapper>
      <Controller
        control={control}
        name={name}
        render={({ field: { onChange, value } }) => (
          <FormControlLabel control={<MuiSwitch onChange={onChange} checked={value} />} label={label} />
        )}
      />
    </SwitchFieldWrapper>
  );
};

const SwitchFieldWrapper = styled('div')`
  margin-bottom: 4px;
  padding-left: 8px;
`;

export default SwitchField;
