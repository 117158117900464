import { createContext, ReactElement, ReactNode, useContext, useMemo, useState } from 'react';

import { LedgerFilterDefs } from '../constants/filterConstants';

interface FiltersContextType {
  onFilterAction: (filterValue: Record<string, string | boolean | number>) => void;
  filterActionPayload: Record<string, any>;
}

const FiltersContext = createContext<FiltersContextType>({} as FiltersContextType);

// The provider to wrap the entire app with it
export function FiltersProvider({ children }: { children: ReactNode }): ReactElement {
  const [filterActionPayload, setFilterActionPayload] = useState({});

  const memoedValue = useMemo(
    () => ({
      onFilterAction: (value: Record<LedgerFilterDefs, any>) => {
        setFilterActionPayload(value);
      },
      filterActionPayload,
    }),
    [filterActionPayload]
  );

  return <FiltersContext.Provider value={memoedValue}>{children}</FiltersContext.Provider>;
}

// We only want to use the hook `useFiltersData` directly and never the context component
export default function useFiltersData(): FiltersContextType {
  return useContext(FiltersContext);
}
