/* eslint-disable max-len */
export const clearLedgersCache = (cache: Record<string, any>) => {
  clearCacheForKey(cache, 'accountLedgers');
};

export const clearPayoutsCache = (cache: Record<string, any>) => {
  clearCacheForKey(cache, 'accountPayouts');
};

const clearCacheForKey = (cache: Record<string, any>, cacheKey: string): void => {
  // Loops through all the data in the Apollo cache
  // and deletes any items that start with "accountLedgers".
  // This empties the cache of all of our accountLedgers and
  // forces a refetch of the data.
  // https://medium.com/@martinseanhunt/how-to-invalidate-cached-data-in-apollo-and-handle-updating-paginated-queries-379e4b9e4698
  Object.keys(cache?.data?.data?.ROOT_QUERY || {}).forEach((key = '') => {
    const matcherRegexp = new RegExp(`^${cacheKey}`);

    key.match(matcherRegexp) && cache.evict({ id: 'ROOT_QUERY', fieldName: key });
  });
};
