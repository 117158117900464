import { AccountType } from './accountConstants';
import { getOptions } from 'src/helpers/commonHelpers';

export const REQUEST_ID_TYPE_KEY = 'requestIdType';

export type FilterValuesType = {
  [AccountType.account]?: string;
  [AccountType.business]?: string;
  [REQUEST_ID_TYPE_KEY]: AccountType;
};

export type MainFiltersProps = {
  requestIdType: AccountType.account | AccountType.business | '';
  id: string;
};

// Ledgers
export enum LedgerFilterDefs {
  unpaidLedgers = 'unpaidLedgers',
  unbalancedLedgers = 'unbalancedLedgers',
  types = 'types',
  refTypes = 'refTypes',
  excludeTypes = 'excludeTypes',
  excludeRefTypes = 'excludeRefTypes',
  payoutIds = 'payoutIds',
  refIds = 'refIds',
  startTime = 'startAt',
  endTime = 'endAt',
  timeType = 'timeType',
  orderDir = 'orderDir',
}

export enum TimeTypeDefs {
  CREATED_AT = 'CREATED_AT',
  UPDATED_AT = 'UPDATED_AT',
  SCHEDULED_AT = 'SCHEDULED_AT',
  BALANCED_AT = 'BALANCED_AT',
}

export enum OrderDirDefs {
  ASC = 'ASC',
  DESC = 'DESC',
}

export const OrderDirOptions = getOptions(OrderDirDefs, true);

export type LedgerFilterValuesType = {
  [LedgerFilterDefs.unpaidLedgers]: boolean;
  [LedgerFilterDefs.unbalancedLedgers]: boolean;
  [LedgerFilterDefs.types]: string[];
  [LedgerFilterDefs.refTypes]: string[];
  [LedgerFilterDefs.excludeTypes]: string[];
  [LedgerFilterDefs.excludeRefTypes]: string[];
  [LedgerFilterDefs.payoutIds]: string[];
  [LedgerFilterDefs.refIds]: string[];
  [LedgerFilterDefs.startTime]: Date | null;
  [LedgerFilterDefs.endTime]: Date | null;
  [LedgerFilterDefs.timeType]: TimeTypeDefs | '';
  [LedgerFilterDefs.orderDir]: OrderDirDefs | '';
};

export const ledgerFiltersDefaultValues: LedgerFilterValuesType = {
  [LedgerFilterDefs.unpaidLedgers]: false,
  [LedgerFilterDefs.unbalancedLedgers]: false,
  [LedgerFilterDefs.types]: [],
  [LedgerFilterDefs.refTypes]: [],
  [LedgerFilterDefs.excludeTypes]: [],
  [LedgerFilterDefs.excludeRefTypes]: [],
  [LedgerFilterDefs.payoutIds]: [],
  [LedgerFilterDefs.refIds]: [],
  [LedgerFilterDefs.startTime]: null,
  [LedgerFilterDefs.endTime]: null,
  [LedgerFilterDefs.timeType]: '',
  [LedgerFilterDefs.orderDir]: '',
};

export type MAIN_FILTERS_TYPE = FilterValuesType | null | undefined;
export type ADDITIONAL_FILTERS_TYPE = LedgerFilterValuesType | Record<string, undefined | string | string[]> | null;
