import styled from '@emotion/styled';
import { get } from 'lodash';

import { Box, Skeleton } from '@mui/material';

import { PricingProfile } from '../constants/pricingProfileConstants';
import { PriceProfileLabel, PriceProfileValue, PriceProfileWrapper } from './CommonLayout';

type PriceProfileInfoProps = {
  priceProfileData: PricingProfile;
  priceProfileIsLoading: boolean;
  isCurrent?: boolean;
};

const PriceProfileInfo = ({ priceProfileIsLoading, priceProfileData, isCurrent }: PriceProfileInfoProps) => {
  const priceProfileRows = [
    {
      label: 'In-person',
      value: get(priceProfileData, 'inPersonFee', ''),
    },
    {
      label: 'E-comm/invoicing',
      value: get(priceProfileData, 'ecommInvoicingFee', ''),
    },
    {
      label: 'Keyed',
      value: get(priceProfileData, 'keyedInFee', ''),
    },
    {
      label: 'Invoicing',
      value: get(priceProfileData, 'invoicingFee', ''),
    },
    {
      label: 'Pay links',
      value: get(priceProfileData, 'paylinksFee', ''),
    },
    {
      label: 'Pay buttons',
      value: get(priceProfileData, 'paybuttonsFee', ''),
    },
    {
      label: 'Payout next day',
      value: get(priceProfileData, 'payoutNextDay', ''),
    },
  ];

  const priceProfileType = isCurrent ? 'Current' : 'New';

  const priceProfileHeader = (
    <PriceProfileHeader>
      {[
        {
          label: `${priceProfileType.toUpperCase()} PRICE PROFILE ID`,
          value: get(priceProfileData, 'priceProfileId', ''),
        },
        {
          label: `${priceProfileType} Price Profile Name`,
          value: get(priceProfileData, 'priceProfileName', ''),
        },
      ].map(({ label, value }) => (
        <PriceProfileRow key={label} isLoading={priceProfileIsLoading} label={label} value={value} />
      ))}
    </PriceProfileHeader>
  );

  return (
    <PriceProfileInfoWrapper>
      {priceProfileHeader}
      {priceProfileRows.map(({ label, value }) => (
        <PriceProfileRow key={label} isLoading={priceProfileIsLoading} label={label} value={value} />
      ))}
    </PriceProfileInfoWrapper>
  );
};

const PriceProfileRow = ({ label, value, isLoading }: { label: string; value: string; isLoading?: boolean }) => {
  return (
    <PriceProfileWrapper>
      <PriceProfileLabel>{label}</PriceProfileLabel>
      <PriceProfileValue>
        {isLoading ? (
          <Box sx={{ width: 60 }}>
            <Skeleton variant="text" />
          </Box>
        ) : (
          value
        )}
      </PriceProfileValue>
    </PriceProfileWrapper>
  );
};

const PriceProfileInfoWrapper = styled('div')`
  padding-right: 12px;
  min-width: 300px;
  width: 80%;
`;

export const PriceProfileHeader = styled('div')`
  margin-bottom: 12px;
`;

export default PriceProfileInfo;
