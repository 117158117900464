import { FileType } from 'src/constants/reportConstants';

export interface ReportListType {
  reportList: FileType[];
}

export enum AggregatedReportType {
  CommissionReport = 'CommissionReport',
  SalesCompensationReport = 'SalesCompensationReport',
}
