import moment from 'moment-timezone';
import { isNil, isNumber } from 'lodash';

import { DEFAULT_TIME_FORMAT, DEFAULT_TIMEZONE, DISABLED_SCHEDULE_LABEL } from 'src/constants/dateTimeConstants';

export const stringifyDate = (date: unknown): string =>
  date ? moment(date).tz(DEFAULT_TIMEZONE).format(DEFAULT_TIME_FORMAT) : '';

export const convertHoursFrom24to12 = (hours: number | null | undefined | any): string => {
  if (hours === -1 || hours === '-1') {
    return DISABLED_SCHEDULE_LABEL;
  }

  if (isNil(hours) || hours > 23 || hours < -1 || !isNumber(hours)) {
    return '';
  }

  if (hours === 12) {
    return '12pm';
  }

  if (hours === 0) {
    return '12am';
  }

  const amPm = hours >= 12 ? 'pm' : 'am';

  return `${hours % 12}${amPm}`;
};
