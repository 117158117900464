import { ReactElement, ReactNode } from 'react';
import styled from '@emotion/styled';

const PanelRow = ({ label, value }: { label: string; value: string | ReactNode }): ReactElement => {
  return (
    <PanelRowWrapper>
      <PanelRowLabel>{label}</PanelRowLabel>
      <PanelRowValue>{value}</PanelRowValue>
    </PanelRowWrapper>
  );
};

export const PanelRowWrapper = styled('div')`
  display: flex;
  font-size: 12px;
  padding-right: 12px;
`;

export const PanelRowLabel = styled('div')`
  opacity: 0.55;
  font-weight: 600;
  margin-right: 8px;
`;

export const PanelRowValue = styled('div')`
  opacity: 0.8;
  font-weight: 600;
`;

export default PanelRow;
