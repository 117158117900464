import { map } from 'lodash';

import { DropdownItemType } from 'src/components/form/formTypes';
import { BankAccountType } from '../constants/createPayoutFromLedgersConstants';

export const getBankAccountDropdownData = (bankAccounts: BankAccountType[]): DropdownItemType[] => {
  const bankAccountsDropdownData = map(
    bankAccounts,
    ({ id, accountNumberLastFour, bankName, finInstrumentShortId, isDefault }) => {
      const label = `${bankName} ${accountNumberLastFour ? `****${accountNumberLastFour}` : ''}${
        finInstrumentShortId ? ` - ${finInstrumentShortId}` : ''
      }${isDefault ? ' (default)' : ''}`;

      return {
        value: id,
        label,
      };
    }
  );

  return bankAccountsDropdownData;
};
