import { ReactElement, ReactNode } from 'react';
import styled from '@emotion/styled';

export const HierarchyWrapper = styled('div')`
  border-left: 2px solid #b6b6b6;
  border-bottom: 2px solid #b6b6b6;
  position: relative;
  padding-top: 16px;
`;

export const ChildTxnWrapper = styled('div')`
  position: relative;
  left: 16px;
  top: 56px;
  margin-right: 18px;
`;

export const InfoPanelWrapper = styled('div')`
  background: #f0f0f0;
  border: 1px solid #e6e6e6;
  min-height: calc(100% - 60px);
  min-width: 320px;
  padding: 4px 8px 12px 12px;
  position: relative;
`;

export const InfoPanelHeader = styled('p')`
  font-weight: 600;
  margin: 8px 0;
  text-transform: uppercase;
`;

export const DetailsRow = ({ label = '', value }: { label?: string; value: string | ReactNode }): ReactElement => {
  return (
    <DetailsRowWrapper>
      <DetailsRowLabel>{label}</DetailsRowLabel>
      <DetailsRowValue>{value}</DetailsRowValue>
    </DetailsRowWrapper>
  );
};

const DetailsRowWrapper = styled('div')`
  border-bottom: 1px solid #dadada;
  display: flex;
  font-size: 13px;
  padding: 8px 0 6px 0;
`;

const DetailsRowLabel = styled('div')`
  align-items: center;
  display: flex;
  opacity: 0.55;
  font-weight: 600;
  margin-right: 8px;
`;

const DetailsRowValue = styled('div')`
  opacity: 0.8;
  font-weight: 600;
`;

export const DetailsUrlWrapper = styled('div')`
  padding-top: 16px;
`;
