import { Component, ErrorInfo, ReactNode } from 'react';
import styled from 'styled-components';
import Button from '@mui/material/Button';

interface ErrorBoundaryProps {
  children: ReactNode;
}

interface ErrorBoundaryStateProps {
  hasError: boolean;
}

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryStateProps> {
  public state: ErrorBoundaryStateProps = {
    hasError: false,
  };

  public static getDerivedStateFromError(): ErrorBoundaryStateProps {
    return { hasError: true };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error('Uncaught error:', error, errorInfo); // eslint-disable-line no-console
  }

  public render() {
    if (this.state.hasError) {
      return (
        <ErrorBoundaryContainer data-aid="error-boundary-container">
          <Row>
            <Item data-aid="error-boundary-message">
              <p>Something went wrong!</p>
              <p>Please refresh or come back at a later time</p>
            </Item>
            <Item>
              <Button
                data-aid="error-boundary-button"
                onClick={() => {
                  window.location.reload();
                }}
              >
                Reload
              </Button>
            </Item>
          </Row>
        </ErrorBoundaryContainer>
      );
    }

    return this.props.children;
  }
}

const ErrorBoundaryContainer = styled.div`
  align-items: center;
  display: flex;
  height: 100vh;
  justify-content: center;
  margin: 0;
  padding: 0;
  width: 100vw;
`;

ErrorBoundaryContainer.displayName = 'ErrorBoundaryContainer';

const Row = styled.div`
  width: auto;
`;

Row.displayName = 'Row';

const Item = styled.div`
  padding: 5px;
  text-align: center;
`;

Item.displayName = 'Item';

export default ErrorBoundary;
