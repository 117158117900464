import styled from 'styled-components';
import Button from '@mui/material/Button';

export const PrimaryButton = styled(Button)`
  min-width: 120px;
`;

export const FormActions = styled('div')`
  padding: 12px 0;

  Button:not(:last-child) {
    margin-right: 12px;
  }
`;
